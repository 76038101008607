import React from 'react'
import {useTranslation} from "react-i18next";

const NotFound = props => {
    const {t} = useTranslation();
    return (
        <>
            <div style={{width:'100%', textAlign:'center'}}>
                <div style={{marginTop:'100px'}}>
                    <p className="text-muted" style={{marginTop:'10px'}}>{t('msg_pagina_nao_encontrada')}</p>
                </div>
            </div>
        </>
    )
}

export default NotFound