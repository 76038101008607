import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import Container from "./Container";

const Hub = props => {
    return (
        <>
            <Router>
                <Routes>
                    <Route path=":clientid/*" element={<Container/>} />
                    <Route path=":clientid/:view" element={<Container/>} />
                    <Route path=":clientid/:view/:view2" element={<Container/>} />
                    <Route path="*" element={<Navigate to='/default' />} />
                </Routes>
            </Router>
        </>
    )
}
export default Hub