const Storage = class {

    constructor() {
        this.id = 'safra'
    }

    set(type, key, item){
        let storage = window.sessionStorage;
        if(type === 'local'){
            storage = window.localStorage;
        }
        storage.setItem(this.id+'.'+key,JSON.stringify(item));
    }

    onAuthStateChange(){}

    get(type,key){
        let storage = window.sessionStorage;
        if(type === 'local'){
            storage = window.localStorage;
        }
        let valor = storage.getItem(this.id+'.'+key);
        if(valor){
            return JSON.parse(valor);
        }else{
            return null;
        }
    }

    remove(type,key){
        let storage = window.sessionStorage;
        if(type === 'local'){
            storage = window.localStorage;
        }
        storage.removeItem(this.id+'.'+key);
    }

    getKeys(type, startsWith){
        let storage = window.sessionStorage;
        if(type === 'local'){
            storage = window.localStorage;
        }
        const rs = [];
        for(const key of Object.keys(storage)){
            const prefix = this.id+'.';
            if(startsWith && startsWith !== ''){
                if(key.startsWith(this.id+'.'+startsWith)){
                    rs.push(key.substring(prefix.length, key.length));
                }
            }else{
                if(key.startsWith(this.id)){
                    rs.push(key.substring(prefix.length, key.length));
                }
            }
        }
        return rs
    }

    clear(){
        window.sessionStorage.clear();
        window.localStorage.clear();
    }
}
export default new Storage()