import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "../../context/AuthContext";

const Teste = (props) => {

    const {t} = useTranslation();

    const {user} = useContext(AuthContext);

    useEffect(()=>{
        if(user){}
    },[user])

    return (
        <>
           Teste
        </>
    )

}

export default Teste