import React, {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import Loader from "../../util/Loader";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDropzone} from "react-dropzone";
import RHFSelect from "../../forms/RHFSelect";
import {useForm} from "react-hook-form";
import {Dropdown} from "primereact/dropdown";
import SafraContext from "../../../context/SafraContext";

import { Toast } from 'primereact/toast';
import {Checkbox} from "primereact/checkbox";

const ModalImportarXLSX = forwardRef((props, ref) => {

    const {t} = useTranslation();

    const {view, collection, maxFiles = 1, accept, configuration, action = {}} = props;

    const {importarCadastroXLSX, cadastroFilter, getStateMap} = useContext(SafraContext);

    useImperativeHandle(
        ref,
        () => ({
            setLoader(show, msg, error) {
                setLoader({show, msg, error})
            }
        }),
    )
    const toast = useRef(null);

    const [showModal, setShowModal] = useState(props.show);
    const [loader, setLoader] = useState({show:false, msg:'', error:false})
    const [files, setFiles] = useState([]);

    const [sheets, setSheets] = useState([]);
    const [workbook, setWorkbook] = useState();
    const [jsonSheet, setJsonSheet] = useState();
    const [header, setHeader] = useState([])
    const [fileData, setFileData] = useState();
    const [errorFile, setErrorFile] = useState();
    const [table, setTable] = useState([])

    const refSelectSheets = useRef();
    const refInputIndexHeader = useRef();

    const refDivForm = useRef();
    const refSubmitBtn = useRef();
    const refDivHeader = useRef()

    useEffect(()=>{

    },[])

    useEffect(() => {
        if(configuration){
            setTable((configuration.table) ? configuration.table : []);
        }
    }, [configuration]);

    useEffect(()=>{
        setShowModal(props.show);
    },[props.show])

    const onShow = ()=>{
        if(props.onShow) props.onShow()
    }
    const onExited = ()=>{
        setLoader({show:false, msg:'', error: false})

        setFiles([]);
        refSelectSheets.current.clearSelect();
        setSheets([])
        setWorkbook(null);
        setJsonSheet(null);
        setSelectedHeaderField({});
        setSelectedKeyField([])
        setErrorFile(null);
        setFileData(null);

        if(props.onClose) props.onClose();
    }

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);

        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                refSelectSheets.current.clearSelect();
                const fileArrayBuffer = new Uint8Array(reader.result);
                setFileData(fileArrayBuffer);

                let workbook = window.XLSX.read(fileArrayBuffer, {type: 'array'});
                setWorkbook(workbook);

                const sheetList = []
                for(const sheetName of Object.keys(workbook.Sheets)){
                    sheetList.push({value:sheetName, label:sheetName})
                }
                setSheets(sheetList);

                refDivForm.current.style.display = "block"
                refDivHeader.current.style.display = "none"
                refSubmitBtn.current.style.display = "none";
                setErrorFile(null);
            }
            reader.readAsArrayBuffer(file)
        })

    }, [])

    const {getRootProps, getInputProps, acceptedFiles, open} = useDropzone({
        maxFiles,
        onDrop,
        accept
    })

    const fileItems  = files.map(file => (
        <span className="font-weight-bold pt-1" key={file.path}>
            {file.path} - {file.size} bytes
        </span>
    ));

    const {
        register,
        control,
        setValue,
        watch,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            'index-header': 1
        }
    });

    const [selectedKeyField, setSelectedKeyField] = useState([]);
    const onSelectedKeyFieldChange = (e) => {
        let _selectedCategories = [...selectedKeyField];
        if (e.checked) _selectedCategories.push(e.value);
        else _selectedCategories = _selectedCategories.filter(field => field !== e.value);
        setSelectedKeyField(_selectedCategories);
    };

    const [selectedHeaderField, setSelectedHeaderField] = useState({})

    const onChangeSheet = (name, item)=>{
        if(!item || !workbook) {
            return;
        }
        const worksheet = workbook.Sheets[item.value];
        const jsonSheetObj = window.XLSX.utils.sheet_to_json(worksheet, {header: 1});
        setJsonSheet(jsonSheetObj);
        setValue("index-header", 1);

        const indexHeader = refInputIndexHeader.current ? refInputIndexHeader.current.value : 1;
        ajustarCampos(indexHeader,jsonSheetObj);

        if(configuration && configuration.pk){
            setSelectedKeyField(configuration.pk);
        }

        refDivHeader.current.style.display = "block"
        refSubmitBtn.current.style.display = "block"
    }
    const handleIndexChange = (event)=>{
        const indexHeader = event.target.value;
        setSelectedHeaderField({})
        ajustarCampos(indexHeader, jsonSheet);
    }
    const ajustarCampos = (indexHeader, jsonSheetObj)=>{
        let headerRowIndex = parseInt( (indexHeader+"" !== '') ? indexHeader : "1");
        headerRowIndex--;if(headerRowIndex < 0) headerRowIndex = 0;
        if(jsonSheetObj && jsonSheetObj[headerRowIndex] && Array.isArray(jsonSheetObj[headerRowIndex])){
            //Pre selecionar as referencias
            for(const [, item] of table.entries()){
                let selectedHeaderIndex = -1
                for(const [index, head] of jsonSheetObj[headerRowIndex].entries()){
                    if((head+"").toLowerCase() === (item.title+"").toLowerCase()){
                        selectedHeaderIndex = index;
                        break;
                    }
                }
                if(selectedHeaderIndex >= 0){
                    setSelectedHeaderField((prevState) => ({
                        ...prevState,
                        [item.field]: jsonSheetObj[headerRowIndex][selectedHeaderIndex],
                    }))
                }
            }
            setHeader(jsonSheetObj[headerRowIndex]);
        }else{
            setHeader([]);
        }
    }



    const onSubmit = (data, e) => {

        let headerRowIndex = parseInt( (data['index-header']+"" !== '') ? data['index-header'] : "1");
        headerRowIndex--;if(headerRowIndex < 0) headerRowIndex = 0;

        const fieldHeaderIndex = {}
        if(jsonSheet){
            const headerIndex = {}
            for(const [index, head] of jsonSheet[headerRowIndex].entries()){
                headerIndex[head] = index
            }
            for(const field of Object.keys(selectedHeaderField)){
                fieldHeaderIndex[field] = headerIndex[selectedHeaderField[field]];
            }
        }

        for(const [, item] of table.entries()){
            if(cadastroFilter[collection] && cadastroFilter[collection][item.field] && item._type === "reference") {
                fieldHeaderIndex[item.field] = cadastroFilter[collection][item.field];
            }
        }

        setLoader({show:true, msg:t('importando'), error: false});
        const fnImportarXLSX = (action && action.importarXLSX) ? action.importarXLSX :  importarCadastroXLSX
        fnImportarXLSX(view, collection, fileData, data.sheets.value, headerRowIndex, fieldHeaderIndex, selectedKeyField).then((rs)=>{
            setLoader({show:false, msg:'', error: false})

            if(!rs || rs.error){
                toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_salvar'), life: 2000});
                return;
            }

            if(rs.errors){
                //console.log(rs);
                toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_salvar'), life: 2000});
                setErrorFile(rs.file);
                return;
            }

            if(props.onSave){
                props.onSave(rs);
            }
            props.close();
        }).catch(err=>{
            setLoader({show:false, msg:'', error: false})
            console.error(err);
        })
    }


    const handleXLSXDownload = (fileData) => {
        try {
            const arrayBuffer = new Uint8Array(fileData.data).buffer;

            const workbook = window.XLSX.read(arrayBuffer, { type: 'array' });
            const wbout = window.XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([wbout], { type: 'application/octet-stream' });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'errors.xlsx';
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Erro ao gerar o arquivo XLSX:', error);
        }
    };


    return (
        <>
            <Modal
                show={showModal}
                onExited={onExited}
                onShow={onShow}
                animation={true}
                dialogClassName="modal-lg"
            >
                <Loader show={loader.show} msg={loader.msg} type="modal"/>
                <Toast ref={toast} appendTo={"self"} position="bottom-center" />

                <Modal.Header className="bg-light p-2 pl-3">
                    <span className="font-weight-semibold">{t('importar_tipo', {tipo: "XLSX"})}</span>
                </Modal.Header>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <p>{t('info_dropzone')}</p>
                        </div>
                        <div className="mt-2">
                            {fileItems}
                        </div>
                        <div ref={refDivForm} style={{display:"none"}}>
                            <div className="form-group">
                                <label className="font-weight-bold">{t('planilha')}</label>
                                <RHFSelect
                                    ref={refSelectSheets}
                                    name="sheets"
                                    placeholder={t('selecione')}
                                    control={control}
                                    isClearable={false}
                                    options={sheets}
                                    handleChange={onChangeSheet}
                                />
                            </div>
                            <div ref={refDivHeader} style={{display:"none"}}>
                                <div className="form-group mb-3">
                                    <label className="font-weight-bold">{t('info_index_titulo')}</label>
                                    <input ref={refInputIndexHeader} {...register("index-header", {onChange: handleIndexChange})} type="number" className="form-control form-control-sm"/>
                                </div>
                                <span className="font-size-xs text-muted">{t('chave')}</span>
                                <div>
                                    {table.map((item, index) => {
                                        if (item.field.startsWith("_")) {
                                            return false;
                                        }
                                        if(cadastroFilter[collection] && cadastroFilter[collection][item.field] && item._type === "reference") {
                                            const stateMap = getStateMap(item._crud);
                                            let value = ""
                                            if(stateMap && stateMap[cadastroFilter[collection][item.field]]){
                                                const valueObj = stateMap[cadastroFilter[collection][item.field]]
                                                value = valueObj[item._crud_label]
                                            }
                                            return (
                                                <div key={"field-" + index} style={{display: "flex"}} className="mb-1">
                                                    <Checkbox
                                                        className="pt-1"
                                                        inputId={item.field}
                                                        value={item.field}
                                                        onChange={onSelectedKeyFieldChange}
                                                        checked={selectedKeyField.some((v) => v === item.field)}/>
                                                    <div className="ml-2 mr-2 pt-1" style={{width: "200px"}}>{item.title}</div>
                                                    <input type="text" className="form-control form-control-sm" disabled={true} value={value} />
                                                </div>
                                            )
                                        }else{
                                            return (
                                                <div key={"field-" + index} style={{display: "flex"}} className="mb-1">
                                                    <Checkbox
                                                        className="pt-1"
                                                        inputId={item.field}
                                                        value={item.field}
                                                        onChange={onSelectedKeyFieldChange}
                                                        checked={selectedKeyField.some((v) => v === item.field)} />

                                                    <div className="ml-2 mr-2 pt-1" style={{width:"200px"}}>{item.title}</div>
                                                    <Dropdown
                                                        style={{marginLeft: "auto", width: "100%"}}
                                                        className="custom-dropdown-sm"
                                                        value={selectedHeaderField[item.field] || ''}
                                                        onChange={(e) =>
                                                            setSelectedHeaderField((prevState) => ({
                                                                ...prevState,
                                                                [item.field]: e.value,
                                                            }))
                                                        }
                                                        options={header.map((head, indexHead) => {
                                                            return {label: head, value: head}
                                                        })}
                                                        optionLabel="label"/>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <span className="font-size-xs">* {t('info_selecione_campo_chave')}</span>
                            </div>
                            <div className="mt-2">
                                {(errorFile) ?
                                    <>
                                        <div className="text-danger">{t('info_erro_xlsx')}</div>
                                        <button type="button" className="btn btn-light btn-sm btn-labeled btn-labeled-left" onClick={(e) => handleXLSXDownload(errorFile)}>
                                            <b><i className="icon-exclamation"></i></b>
                                            {t('download')}
                                        </button>
                                    </> : <></>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button ref={refSubmitBtn} type="submit" variant="primary" style={{display: "none"}}>{t('importar')}</Button>
                        <Button variant="light" onClick={props.close}>{t('fechar')}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )

});

export default ModalImportarXLSX