import EventEmitter from "events";

export default class GenericControl extends EventEmitter {

    /**
     * @param api {string} API URL
     * @param client {string} Cliente
     * @param user {UserSession=} User Session Obj
     */
    init(api, client, user) {
        this.api = api;
        this.client = client;
        this.user = user;
        this.cacheID = 'safra-web'
        this.clear();
    }

    /**
     * @param request {Request}
     * @param response {Response}
     * @returns {Promise<boolean>}
     */
    async cache(request, response) {
        if ('caches' in window) {
            const cache = await caches.open(this.cacheID);
            await cache.put(request, response)
            return true;
        }else{
            return false;
        }
    }

    /**
     * @param request {Request}
     * @returns {Promise<Response|null>}
     */
    async cached(request){
        if ('caches' in window) {
            const cache = await caches.open(this.cacheID);
            return await cache.match(request);
        }else{
            return null
        }
    }

    clear(event){
        if(!event) this.removeAllListeners()
        else this.removeAllListeners(event)
    }
}