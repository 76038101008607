import { ResponsiveRadialBar } from '@nivo/radial-bar'
import {forwardRef} from "react";
import {useTranslation} from "react-i18next";

const RadialChart = forwardRef((props, ref) => {

    const {t} = useTranslation();

    const data = [
        {
            "id": "Fazenda Jardins - Jardins",
            "data": [
                {
                    "x": "Tamanho",
                    "y": 2127
                },
                {
                    "x": "Qtd Ha",
                    "y": 2127
                }
            ]
        },
        {
            "id": "Fazenda Lagoa - Lagoa",
            "data": [
                {
                    "x": "Tamanho",
                    "y": 2658
                },
                {
                    "x": "Qtd Ha",
                    "y": 2472
                }
            ]
        }
    ]

    return (
        <ResponsiveRadialBar
            data={data}
            valueFormat=">-.2f"
            padding={0.4}
            cornerRadius={2}
            margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
            radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
            circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
            legends={[
                {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 80,
                    translateY: 0,
                    itemsSpacing: 6,
                    itemDirection: 'left-to-right',
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    symbolSize: 18,
                    symbolShape: 'square',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
        />
    )

});

export default RadialChart