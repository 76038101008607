import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "./../../../context/AuthContext";
import SafraContext from "./../../../context/SafraContext";
import Swal from "sweetalert2";
import TableCrudAdm from "../../util/TableCrudAdm";

import {startLoadingAction, stopLoadingAction} from "../../util/Util";
import ModalNew from "../modal/ModalNew";
import {Toast} from "primereact/toast";
import ModalNewAdm from "../modal/ModalNewAdm";


const CrudAdm = ({id, view, configuration, validation, focus}) => {

    const {t} = useTranslation();
    const {user, clientID} = useContext(AuthContext);
    const {api} = useContext(SafraContext);

    const [data, setData] = useState([]);
    const [table, setTable] = useState([]);

    const toast = useRef(null);

    const handleKey = (event) => {
        if (event.key === '+') {
            newItem();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKey);
        return () => {
            window.removeEventListener('keydown', handleKey);
        };
    }, []);

    const refTable = useRef();

    useEffect(()=>{
        if(user){
            api(view, id, "listar").then((rs)=>{
                setData(rs);
            })
        }
    },[user])

    useEffect(() => {
        if(configuration){
            setTable((configuration.table) ? configuration.table : []);
        }
    }, [configuration]);

    const remover = async (event)=>{
        const btn = event.currentTarget;
        startLoadingAction(btn);
        const selected = refTable.current.getSelected();

        if(selected && selected.length > 0){
            const pop = await Swal.fire({
                title: t('info_voce_tem_certeza'),
                text: t('info_registros_removido', {count:selected.length}),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('sim'),
                cancelButtonText: t('cancelar')
            })
            if(pop.isConfirmed){
                const ids = []
                for(const [, item] of selected.entries()){
                    ids.push(item._id);
                }
                refTable.current.setLoading(true);
                await api(view, id, "remover", {ids});
                const rs = await api(view, id, "listar");
                setData(rs);
                refTable.current.setLoading(false);
                stopLoadingAction(btn);
            }else{
                stopLoadingAction(btn);
            }
        }else{
            stopLoadingAction(btn);
        }
    }

    const resetPwd = async (event)=>{
        const btn = event.currentTarget;
        startLoadingAction(btn);
        const selected = refTable.current.getSelected();
        if(selected && selected.length > 0){
            const pop = await Swal.fire({
                title: t('info_voce_tem_certeza'),
                text: t('info_reset_password', {count:selected.length}),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('sim'),
                cancelButtonText: t('cancelar')
            })
            if(pop.isConfirmed){
                const ids = []
                for(const [, item] of selected.entries()){
                    ids.push(item._id);
                }
                refTable.current.setLoading(true);
                await api(view, id, "resetPwd", {ids});
                const rs = await api(view, id, "listar");
                setData(rs);
                refTable.current.setLoading(false);
                stopLoadingAction(btn);
            }else{
                stopLoadingAction(btn);
            }
        }else{
            stopLoadingAction(btn);
        }
    }

    const [newModalShow, setNewModalShow] = useState(false);
    const newItem = (app)=>{
        setNewModalShow(true);
    }
    const newModalClose = ()=>{
        setNewModalShow(false)
    }

    const onSave = ()=>{
        refTable.current.setLoading(true);
        api(view, id, "listar").then((rs)=>{
            setData(rs);
            refTable.current.setLoading(false);
        }).catch(err=>{
            console.error(err);
            toast.current.show({severity:'error', summary: t(id), detail:t('error_listar'), life: 2000});
            refTable.current.setLoading(false);
        })
    }

    const onEdit = async (item)=>{
        const update = {id:item._id, set:{}}
        for(const [, conf] of table.entries()){
            if(conf.field.startsWith('_')) continue;
            if(item.hasOwnProperty(conf.field)){
                update.set[conf.field] = item[conf.field]
            }
        }
        return await api(view, id, "update", update);
    }

    return (
        <>
            <Toast ref={toast} appendTo={"self"} position="top-center" />
            <div className="card">
                <div className="card-body p-1">

                    <div className="row">
                        <div className="col-md-6 text-left">
                            <div className="btn-group">
                                <button type="button" className="btn btn-sm btn-secondary btn-labeled btn-labeled-left" onClick={(event) => {
                                    newItem()
                                }}>
                                    <b><i className="icon-plus2"></i></b> {t('adicionar')}
                                </button>

                            </div>
                        </div>
                        <div className="col-md-6 text-right">
                            {(view === "adm_usuario") ?
                                <>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-sm btn-light btn-labeled btn-labeled-left" onClick={(event) => {resetPwd(event).catch(err => console.error(err))}}>
                                            <b><i className="icon-undo"></i></b> {t('reset_password')}
                                        </button>
                                    </div>
                                </> : <></>}

                            <div className="btn-group ml-2">
                                <button type="button" className="btn btn-sm btn-danger btn-labeled btn-labeled-left"
                                        onClick={(event) => {
                                            remover(event).catch(err => console.error(err))
                                        }}>
                                    <b><i className="icon-cross"></i></b> {t('remover')}
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="card-body p-0 border-top-0">
                    <div style={{height: '100%'}}>
                        <TableCrudAdm ref={refTable} view={view} id={id} configuration={configuration} data={data} onEdit={onEdit} validation={validation}/>
                    </div>
                </div>
            </div>

            <ModalNewAdm
                view={view}
                id={id}
                title={t(id)}
                focus={focus}
                configuration={configuration}
                show={newModalShow}
                close={newModalClose}
                onSave={onSave}
                validation={validation}
            />
        </>
    )

}

export default CrudAdm