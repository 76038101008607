import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState, forwardRef, useRef, useImperativeHandle} from "react";
import AuthContext from "../../../context/AuthContext";
import SafraContext from "../../../context/SafraContext";

import Loader from '../../util/Loader';
import {Button, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import RHFSelect from "../../forms/RHFSelect";

import { Toast } from 'primereact/toast';

const ModalNewAdm = forwardRef((props, ref) => {

    const {view, id, focus, configuration=[], validation=async (item)=>{return {}}} = props;

    const {t} = useTranslation();

    const {user} = useContext(AuthContext);
    const {api} = useContext(SafraContext);

    const [loader, setLoader] = useState({show:false, msg:'', error:false})
    const [showModal, setShowModal] = useState(props.show);
    const [configMap, setConfigMap] = useState({});
    const [table, setTable] = useState([]);

    useImperativeHandle(
        ref,
        () => ({
            setLoader(show, msg, error) {
                setLoader({show, msg, error})
            }
        }),
    )

    const fieldRefs = useRef({});
    const toast = useRef(null);

    useEffect(()=>{
        setShowModal(props.show);
    },[props.show])

    useEffect(()=>{
        if(showModal){

        }
    },[showModal])

    useEffect(() => {
        if(configuration){
            setTable((configuration.table) ? configuration.table : []);
        }
    }, [configuration]);

    useEffect(() => {
        const map = {}
        for(const [,item] of table.entries()){
            map[item.field] = item;
        }
        setConfigMap(map);
    }, [table]);

    const {
        register,
        control,
        watch,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm();

    const onSubmit = (data, e) => {

        const salvar = async ()=>{

            let hasError = false;
            for(const field of Object.keys(configMap)){
                const conf = configMap[field];
                const value = data[field]+"";
                if(conf.hasOwnProperty('mandatory') && conf.mandatory && (!value || value.trim() === '')){
                    setError(field, { type:"manual", message:t('info_campo_vazio',{campo:t(field)}) })
                    hasError = true;
                }

                if(conf.hasOwnProperty('unique') && conf.unique){
                    const rs = await api(view, id,"exists",{values:[value.trim()], field});
                    if(rs.length > 0){
                        setError(field, { type:"manual", message:t('info_ja_existe',{valor:t(field)}) })
                        hasError = true;
                    }
                }
            }

            const errorMap =  await validation(data);
            if(errorMap && Object.keys(errorMap).length > 0){
                for(const key of Object.keys(errorMap)){
                    setError(key, { type:"manual", message:errorMap[key] })
                }
                hasError = true;
            }

            if(hasError) return false;

            const entity = {}
            for(const key of Object.keys(data)){
                const conf = configMap[key];
                if(conf.type === 'list'){
                    entity[key] = data[key].value;
                }else{
                    entity[key] = data[key];
                }
            }
            //console.log(entity);
            return await salvarAPI(entity)
        }

        setLoader({show:true, msg:t('salvando'), error: false})
        salvar().then((rs)=>{
            setLoader({show:false, msg:'', error: false})
            if(!rs || rs.error){
                toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_salvar'), life: 2000});
                return;
            }
            if(props.onSave){
                props.onSave(rs);
            }
            props.close();
        }).catch(err=>{
            setLoader({show:false, msg:'', error: false})
            console.error(err);
        })

    }

    const salvarAPI = async (entity)=>{
        return await api(view, id, "salvar", {entity})
    }

    const onShow = ()=>{
        setFocus(focus, { shouldSelect: true })
        if(props.onShow) props.onShow()
    }
    const onExited = ()=>{
        reset();
        setLoader({show:false, msg:'', error: false})
        if(props.onClose) props.onClose();
    }

    return (
        <>
            <Modal
                show={showModal}
                onExited={onExited}
                onShow={onShow}
                animation={true}
                dialogClassName="modal-sm"
            >
                <Loader show={loader.show} msg={loader.msg} type="modal" />
                <Toast ref={toast} appendTo={"self"} position="bottom-center" />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header className="bg-light p-2 pl-3">
                        <span className="font-weight-semibold">{t('novo')}</span>
                    </Modal.Header>
                    <Modal.Body>
                        {table.map((item, index)=>{

                            if(item.type === 'text'){
                                return (
                                    <div key={'form-field-'+index} className="form-group mb-2">
                                        <label className="font-weight-bold">{item.title}</label>
                                        <input ref={(el) => fieldRefs.current[item.field] = el} type="text" placeholder="" {...register(item.field)} className={"form-control form-control-sm" + ((errors[item.field]) ? " border-danger" : "")}/>
                                        <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                                    </div>
                                )
                            }else if(item.type === 'password'){
                                return (
                                    <div key={'form-field-'+index} className="form-group mb-2">
                                        <label className="font-weight-bold">{item.title}</label>
                                        <input ref={(el) => fieldRefs.current[item.field] = el} type="password" placeholder="" {...register(item.field)} className={"form-control form-control-sm" + ((errors[item.field]) ? " border-danger" : "")}/>
                                        <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                                    </div>
                                )
                            }else if(item.type === 'list'){
                                return (
                                    <div key={'form-field-'+index} className="form-group mb-2">
                                        <label className="font-weight-bold">{item.title}</label>
                                        <div className="form-group mb-0 mt-1">
                                            <RHFSelect
                                                ref={(el) => fieldRefs.current[item.field] = el}
                                                name={item.field}
                                                placeholder={t('selecione')}
                                                control={control}
                                                isClearable={!!(item._empty)}
                                                defaultValue={(item.values[0] && !item._empty) ? item.values[0] : ""}
                                                options={item.values}
                                            />
                                        </div>
                                        <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                                    </div>
                                )
                            }else if(item.type === 'date'){
                                return (
                                    <div key={'form-field-' + index} className="form-group mb-2">
                                        <label className="font-weight-bold">{item.title}</label>
                                        <div className="form-group mb-0 mt-1">
                                            <input ref={(el) => fieldRefs.current[item.field] = el} type="date" {...register(item.field)} className={"form-control form-control-sm" + ((errors[item.field]) ? " border-danger" : "")} />
                                        </div>
                                        <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                                    </div>
                                )
                            }else if(item.type === 'datetime'){
                                return (
                                    <div key={'form-field-' + index} className="form-group mb-2">
                                        <label className="font-weight-bold">{item.title}</label>
                                        <div className="form-group mb-0 mt-1">
                                            <input ref={(el) => fieldRefs.current[item.field] = el} type="datetime-local" {...register(item.field)} className={"form-control form-control-sm" + ((errors[item.field]) ? " border-danger" : "")} />
                                        </div>
                                        <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                                    </div>
                                )
                            }
                        })}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">{t('salvar')}</Button>
                        <Button variant="light" onClick={props.close}>{t('fechar')}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )

})

export default ModalNewAdm