import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import { MultiSelect } from 'primereact/multiselect';
import { Controller } from "react-hook-form";

const RHFMultiselect = forwardRef((props, ref) => {

    const { name, control, placeholder, options, defaultValue= []} = props;

    const [optionValues, setOptionValues] = useState([]);

    useImperativeHandle(
        ref,
        () => ({
            setOptions(opts) {
                setOptionValues(opts)
            },
            getOptions(){
                return options;
            }
        }),
    )

    useEffect(()=>{
        setOptionValues(options);
    },[options])

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                return (
                    <>
                        <MultiSelect
                            value={field.value || []}
                            onChange={(e) => field.onChange(e.target.value || [])}
                            options={optionValues}
                            placeholder={placeholder}
                            maxSelectedLabels={3}
                            style={{width: "100%"}}
                        />
                    </>)
            }}
        />
    );
})

export default RHFMultiselect;
