import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "./../../../context/AuthContext";
import CrudAdm from "./CrudAdm";


const Grupo = ({view}) => {

    const {t} = useTranslation();
    const {user, clientID} = useContext(AuthContext);

    const configuration = {
        table: [
            { field: '_id', title:t("id"), type:"id"},
            { field: 'nome', title:t("nome"), type:"text", mandatory:true, unique:true},
            { field: 'descricao', title:t("descricao"), type:"text"},
            { field: 'tipo', title:t("tipo"), type:"list", empty:false,
                "values":[
                    {value:"user", label:t('Comum')},
                    {value:"adm", label:t('administrador')}
                ]
            },
            { field: 'email', title:t("email"), type:"text"}
        ]
    }

    const validation = async (item)=>{
        const errors = {}
        if(!item.nome || item.nome.trim() === ''){
            errors.nome = t('info_campo_vazio',{campo:t('nome')})
        }
        return errors;
    }

    return (
        <>
            <CrudAdm id={"grupo"} view={view} configuration={configuration} validation={validation} focus={"nome"}/>
        </>
    )

}

export default Grupo