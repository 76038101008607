import {Column} from "primereact/column";
import {Button} from 'primereact/button';
import {DataTable} from "primereact/datatable";
import React, {forwardRef, useEffect, useImperativeHandle, useState, useRef, useContext} from "react";

import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import {OverlayPanel} from "primereact/overlaypanel";

import { locale, addLocale } from 'primereact/api';
import {FilterMatchMode} from "primereact/api";
import {useTranslation} from "react-i18next";
import SafraContext from "../../context/SafraContext";


addLocale('pt-BR', {

});

// Defina a localização padrão para 'pt-BR'
locale('pt-BR');

const sleep = (ms)=>{
    return new Promise((resolve, reject)=>{
        setTimeout(()=>{
            resolve()
        },ms)
    })
}


const TableCrudAdm = forwardRef( ({view, id, configuration = {}, data = [], onEdit=async ()=>{}, validation = async (item)=>{return {}}}, ref) => {

    const {t} = useTranslation();

    const {api} = useContext(SafraContext);

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [first, setFirst] = useState(0);
    const [pagination, setPagination] = useState(10);
    const [values, setValues] = useState([]);
    const [table, setTable] = useState([]);

    const dataTableRef = useRef();
    const overlayErrorRef = useRef();
    const [fieldError, setFieldError] = useState({})

    useImperativeHandle(
        ref,
        () => ({
            getSelected: ()=>{
                return selected;
            },
            setLoading:(value)=>{
                setLoading(value);
            }
        }),
    )

    useEffect(() => {
        initFilters();
        setLoading(false);
    }, []);

    useEffect(() => {
        if(configuration){
            setTable((configuration.table) ? configuration.table : []);
        }
    }, [configuration]);

    useEffect(() => {
        setValues(data);
    }, [data]);

    const onPageChange = (e) => {
        setFirst(e.first);
        setPagination(e.rows);
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const initFilters = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
        });
        setGlobalFilterValue('');
    }
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('pesquisar')+'...'} className="p-inputtext-sm" style={{paddingLeft:'3rem'}} />
                </IconField>
            </div>
        );
    };
    const header = renderHeader();

    const allowEdit = (rowData) => {
        return true;
    };

    const inputRefs = useRef({});
    const overlayInputRefs = useRef({});

    const listEditor = (options)=>{
        const editList = options.column.props._editList || []
        return (
            <Dropdown
                value={options.value}
                options={editList}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder={t('selecione')}
                itemTemplate={(option) => {
                    return option.label;
                }}
            />
        );
    }

    const textEditor = (options) => {
        const fieldName = options.field;
        const rowIndex = options.rowIndex;

        if (!inputRefs.current[rowIndex]) {
            inputRefs.current[rowIndex] = {};
        }
        if (!overlayInputRefs.current[rowIndex]) {
            overlayInputRefs.current[rowIndex] = {};
        }

        return (
            <>
                <InputText className="p-inputtext-sm" type="text" value={options.value}
                           ref={(el) => inputRefs.current[rowIndex][fieldName] = el}
                           onChange={(e) => {
                               options.editorCallback(e.target.value);
                               overlayErrorRef.current.hide();
                           }}
                           onKeyDown={(e) => {
                               if (e.key === 'Enter') {
                                   e.preventDefault();
                                   e.stopPropagation();
                                   //console.log('onKeyDown', options);

                                   const row = document.querySelectorAll('.p-datatable-tbody > tr')[options.rowIndex];
                                   const saveButton = row.querySelector('.p-row-editor-save');
                                   if(saveButton) {
                                       saveButton.click();
                                   }
                               }
                           }}
                />
                <OverlayPanel ref={(el) => overlayInputRefs.current[rowIndex][fieldName] = el}>
                    <div className="p-error">{(fieldError[fieldName]) ? fieldError[fieldName] : ""}</div>
                </OverlayPanel>
            </>
        )


    };

    const applyErrorStyle = (rowIndex, field) => {
        if (inputRefs.current[rowIndex] && inputRefs.current[rowIndex][field]) {
            inputRefs.current[rowIndex][field].classList.add("p-invalid");
            if (overlayInputRefs.current[rowIndex] && overlayInputRefs.current[rowIndex][field]) {
                overlayInputRefs.current[rowIndex][field].show(null, inputRefs.current[rowIndex][field])
                inputRefs.current[rowIndex][field].focus();
            }
        }
    };

    const showOverlayError = (event, current)=>{
        overlayErrorRef.current.show(event, current);
        setTimeout(()=>{
            overlayErrorRef.current.hide();
        },2000)
    }

    const onRowEditInit = (e)=>{
        //console.log('onRowEditInit',e);
    }

    const onRowEditComplete = (e)=>{
        //console.log('onRowEditComplete',e);
    }

    const persist = (e)=>{
        //console.log('persist',e);
        setLoading(true);

        const newData = e.newData;
        const index = e.index;
        const row = document.querySelectorAll('.p-datatable-tbody > tr')[index];
        const saveButton = row.querySelector('.p-row-editor-save');
        const cancelButton = row.querySelector('.p-row-editor-cancel');

        onEdit(newData).then((rs)=>{
            if(!rs || rs.error){
                setFieldError({_:t('error_editar')});
                showOverlayError(null, saveButton);
                setLoading(false);
                console.error(rs.error);
            }else{
                let _values = [...values];
                _values[index] = newData;
                setValues(_values);
                setLoading(false);
                cancelButton.click();
            }
        }).catch(err=>{
            setFieldError({_:t('error_editar')});
            showOverlayError(null, saveButton);
            setLoading(false);
            console.error(err);
        })
    }
    
    const onRowEditSave = (e)=>{
        //console.log('onRowEditSave', e);
        const newData = e.newData;
        const oldData = e.data;
        const index = e.index;

        setLoading(true);

        const validarGeral = async ()=>{
            let generalErrors = {}
            for(const [,item] of table.entries()){
                if(item.field.startsWith("_") || item.type === 'password'){
                    continue;
                }
                const value = (newData[item.field]) ? newData[item.field]+"" : "";
                const oldValue = (oldData[item.field]) ? oldData[item.field]+"" : "";

                if(item.hasOwnProperty('mandatory') && item.mandatory && value.trim() === ''){
                    applyErrorStyle(index, item.field, e.originalEvent);
                    generalErrors[item.field] = t('info_campo_vazio',{campo:t(item.field)})
                }

                if(item.hasOwnProperty('unique') && item.unique && value.trim() !== oldValue.trim()){
                    const rs = await api(view, id,"exists",{values:[value], field:item.field});
                    if(rs.length > 0){
                        applyErrorStyle(index, item.field, e.originalEvent);
                        generalErrors[item.field] = t('info_valor_existe')
                    }
                }
            }

            return generalErrors;
        }

        validarGeral().then((generalErrors)=>{
            if(Object.keys(generalErrors).length > 0) {
                setFieldError(generalErrors);
                setLoading(false);
                return
            }

            validation(newData).then((errors)=>{
                setFieldError(errors);
                for(const field of Object.keys(errors)){
                    applyErrorStyle(index, field, e.originalEvent);
                }
                if(Object.keys(errors).length === 0){
                    persist(e);
                }else{
                    setLoading(false);
                }
            }).catch(err=>{
                setFieldError({_:t('error_registro_invalido')});
                showOverlayError(e.originalEvent);
                console.error(err);
            })

        }).catch(err=>{
            setFieldError({_:t('error_registro_invalido')});
            showOverlayError(e.originalEvent);
            console.error(err);
        })

    }

    const rowEditValidator = (data)=>{
        //console.log('rowEditValidator', data);
        return false;
    }

    return (
        <>
            <OverlayPanel ref={overlayErrorRef}>
                {Object.values(fieldError).map((msg, index)=> {
                    return <div key={"error-msg-"+index} className="p-error">{msg}</div>
                })}
            </OverlayPanel>
            <DataTable
                ref={dataTableRef}
                dataKey="_id"
                value={values}
                loading={loading}
                size="small"
                header={header}
                filters={filters}
                globalFilterFields={Object.values(table).map((conf)=>{return conf.field})} emptyMessage={t('msg_nenhum_registro')}

                paginator
                onPage={onPageChange}
                first={first}
                rows={pagination}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"

                selectionMode={'checkbox'}
                selection={selected}
                onSelectionChange={(e) => setSelected(e.value)}
                selectionPageOnly

                editMode="row"
                onRowEditInit={onRowEditInit}
                onRowEditSave={onRowEditSave}
                onRowEditComplete={onRowEditComplete}
                rowEditValidator={rowEditValidator}

            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem'}} bodyStyle={{ textAlign: 'left' }}></Column>
                <Column rowEditor={allowEdit} headerStyle={{width: '5rem' }} bodyStyle={{ textAlign: 'left' }}></Column>
                {table.map((conf, index)=>{
                    if(conf.type === 'list'){
                        const listMap = {};
                        conf.values.map((item)=>{
                            listMap[item.value] = item.label;
                        })
                        return <Column key={index} field={conf.field} header={conf.title} dataType="text" sortable={true} editor={listEditor} _editList={conf.values} body={(item)=>{return listMap[item[conf.field]]}} ></Column>
                    }else if(conf.type === 'id'){
                        return <Column key={index} field={conf.field} header={conf.title} dataType="text" hidden={true} ></Column>
                    }else if(conf.type === 'password'){
                        return <Column key={index} field={conf.field} header={conf.title} dataType="text" hidden={true} ></Column>
                    }else{
                        return <Column key={index} field={conf.field} header={conf.title} dataType="text" sortable={true} editor={textEditor}></Column>
                    }
                })}
            </DataTable>
        </>
    );
});

export default TableCrudAdm