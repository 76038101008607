import {useContext} from "react";
import ConfigContext from "../context/ConfigContext";

export default class TempoControl{

    async ipOrigin(ip){
        const url = 'http://ip-api.com/json/'+ip;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async ipfy(){
        const url = 'https://api.ipify.org?format=json';
        const response = await fetch(url)
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async localeId(token, country, state, city){
        const url = 'http://apiadvisor.climatempo.com.br/api/v1/locale/city?country='+country+'&name='+city+'&state='+state+'&token='+token;
        const response = await fetch(url)
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async register(token, city){
        //const url = 'http://apiadvisor.climatempo.com.br/api-manager/user-token/'+token+'/locales';
        const url = 'https://cors-anywhere.herokuapp.com/http://apiadvisor.climatempo.com.br/api-manager/user-token/'+token+'/locales';
        const response = await fetch(url,{
            method: 'PUT',
            body: new URLSearchParams({
                'localeId[]': city
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async climate(token, country){
        //const url = 'http://apiadvisor.climatempo.com.br/api/v1/anl/synoptic/locale/'+country+'?token='+token;
        const url = 'https://cors-anywhere.herokuapp.com/http://apiadvisor.climatempo.com.br/api/v1/anl/synoptic/locale/'+country+'?token='+token;
        const response = await fetch(url)
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async climateRain(token, localeId){
        //const url = 'http://apiadvisor.climatempo.com.br/api/v1/climate/rain/locale/'+localeId+'?token='+token;
        const url = 'https://cors-anywhere.herokuapp.com/http://apiadvisor.climatempo.com.br/api/v1/climate/rain/locale/'+localeId+'?token='+token;
        const response = await fetch(url)
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }



}

export const tempo = new TempoControl()