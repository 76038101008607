import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import SafraContext from "../../../context/SafraContext";
import Crud from "./Crud";

const UnidadeMedida = ({view}) => {

    const {t} = useTranslation();
    const {cadastroConfiguration, getStateCollection} = useContext(SafraContext);

    const [configuration, setConfiguration] = useState({});
    const [data, setData] = useState([]);

    useEffect(()=>{
        if(cadastroConfiguration[view]){
            setConfiguration(cadastroConfiguration[view]);
        }
    },[cadastroConfiguration])

    useEffect(()=>{
        const collection = getStateCollection(view);
        //console.log(view, "Atualizando Dados", collection);
        setData(collection);
    },[getStateCollection(view)])

    const validation = async (item)=>{
        const errors = {}
        return errors
    }

    return (
        <>
            <Crud
                collection={view}
                view={view}
                data={data}
                validation={validation}
                configuration={configuration}
                focus="nome"
            />
        </>
    )

}

export default UnidadeMedida