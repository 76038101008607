import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "../../context/AuthContext";
import SafraContext from "../../context/SafraContext";
import {Toast} from "primereact/toast";
import Loader from "../util/Loader";
import Crud from "./cadastro/Crud";
import BoxModel from "../util/BoxModel";
import ModalNew from "./modal/ModalNew";
import ControlContext from "../../context/ControlContext";
import {startLoadingAction, stopLoadingAction} from "../util/Util";
import Swal from "sweetalert2";
import {Tooltip} from "primereact/tooltip";

const Modelo = ({view}) => {

    const {t} = useTranslation();

    const {user, clientID} = useContext(AuthContext);
    const {titles} = useContext(ControlContext);
    const {listarCadastro, getStateCollection, cadastroConfiguration, setCadastroFilter, cadastroFilter, removerCadastro, duplicarCadastro} = useContext(SafraContext);

    const toast = useRef(null);

    const [loader, setLoader] = useState({show:true, msg:"", error:false});
    const [itemConfiguration, setItemConfiguration] = useState({});
    const [configuration, setConfiguration] = useState({});

    const [modelos, setModelos] = useState(null);
    const [dataModeloItem, setDataModeloItem] = useState([]);
    const [filtered, setFiltered] = useState([])
    const [search, setSearch] = useState('');

    const [selected, setSelected] = useState();

    const boxRefs = useRef({});

    useEffect(() => {
        const init = async ()=>{
            await listarCadastro(view, "cultura")
            await listarCadastro(view, "modelo");
        }
        init().catch(err=>{
            console.error(err);
        })
        return () => {
            setLoader({show:true, msg:'', error:false})
        };
    }, []);

    useEffect(()=>{
        const collection = getStateCollection(view);
        setModelos(collection);
        setLoader({show:false, msg:'', error:false})
    },[getStateCollection(view)])

    useEffect(()=>{
        const collection = getStateCollection("modelo_item");        
        setDataModeloItem(collection);
    },[getStateCollection("modelo_item")])

    useEffect(()=>{
        if(modelos && cadastroConfiguration[view]){
            setItemConfiguration(cadastroConfiguration["modelo_item"])
            setConfiguration(cadastroConfiguration[view]);
        }
    },[modelos, cadastroConfiguration])

    useEffect(()=>{
        if(modelos){
            const filter = modelos.filter((modelo)=>{
                return (
                    search.trim() === '' ||
                    (modelo.nome && modelo.nome.toLowerCase().includes(search.toLowerCase()))
                );
            })
            setFiltered(filter);
        }
    },[modelos, search])

    /* MODAL NOVO */
    const [newModeloModalShow, setNewModeloModalShow] = useState(false);
    const [editModelo, setEditModelo] = useState(null);
    const newModelo = (event, modelo)=>{
        if(modelo) setEditModelo(modelo);
        else setEditModelo(null);
        setNewModeloModalShow(true);
    }
    const newAppModalClose = ()=>{
        setNewModeloModalShow(false)
    }
    const handleNewModeloClose = ()=>{}
    const handleNewModeloEntered = ()=>{}

    const remover = async (event, modelo)=>{
        const btn = event.currentTarget;
        startLoadingAction(btn);
        const pop = await Swal.fire({
            title: t('info_voce_tem_certeza_fazer',{fazer:t('remover')}),
            text: modelo.nome,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('sim'),
            cancelButtonText: t('cancelar')
        })
        if(pop.isConfirmed){
            setLoader({show:true, msg:'', error:false})
            if(selected && selected._id === modelo._id){
                setSelected(null);
            }
            removerCadastro(view, "modelo", [modelo._id]).then((rs)=>{
                if(!rs || rs.error){
                    toast.current.show({severity:'error', summary: t('modelo'), detail:t('error_remover'), life: 2000});
                    return;
                }
                stopLoadingAction(btn);
            }).catch(err=>{
                stopLoadingAction(btn);
            })
        }else{
            stopLoadingAction(btn);
        }
    }

    const open = async (event, modelo)=>{
        setCadastroFilter({"modelo_item": {modelo:modelo._id}})
        setSelected(modelo);
        for(const id of Object.keys(boxRefs.current)){
            if(!boxRefs.current[id]){
                delete boxRefs.current[id];
            }else{
                boxRefs.current[id].setSelected(null);
            }
        }
        boxRefs.current[modelo._id].setSelected(modelo);
    }

    const edit = async (event, modelo)=>{
        newModelo(event, modelo)
    }

    const duplicate = async (event, modelo)=>{
        const btn = event.currentTarget;
        startLoadingAction(btn);
        const pop = await Swal.fire({
            title: t('info_voce_tem_certeza_fazer',{fazer:t('duplicar')}),
            text: modelo.nome,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('sim'),
            cancelButtonText: t('cancelar')
        })
        if(pop.isConfirmed){
            if(selected && selected._id === modelo._id){
                setSelected(null);
            }
            duplicarCadastro(view, "modelo", modelo._id, {"modelo_item":"modelo"}).then((rs)=>{
                if(!rs || rs.error){
                    toast.current.show({severity:'error', summary: t('modelo'), detail:t('error_duplicar'), life: 2000});
                    return;
                }
                stopLoadingAction(btn);
            }).catch(err=>{
                stopLoadingAction(btn);
            })
        }else{
            stopLoadingAction(btn);
        }
    }

    return (
        <>
            <Toast ref={toast} appendTo={"self"} position="bottom-center"/>
            <Loader type="content" show={loader.show} msg={loader.msg}/>
            <div className="card" style={{minWidth: "315px"}}>
                <div className="card-header bg-light header-elements-sm-inline" style={{padding: '5px'}}>
                    <button onClick={(event) => {newModelo(event)}} type="button" className="btn btn-secondary btn-labeled btn-labeled-left">
                        <b><i className="icon-plus2"></i></b>
                        {t('novo_item',{item:t('modelo')})}
                    </button>
                    <div className="header-elements">
                        <div className="form-group form-group-feedback form-group-feedback-right">
                            <input type="search" className="form-control form-control-lg" placeholder={t('pesquisar')} onChange={(e) => {
                                setSearch(e.target.value)
                            }}/>
                            <div className="form-control-feedback form-control-feedback-lg">
                                <i className="icon-search4 text-muted"></i>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (filtered.length === 0) ?
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 text-center"><span className="text-muted">{t('msg_nenhum_item_encontrado',{item:t("modelo")})}</span></div>
                            </div>
                        </div>
                        :
                        <div className="card-body" style={{padding: 0, margin: '10px 10px 0 0'}}>
                            <div className="row">
                                <div className="col-md-12 p-0">
                                    <div style={{display:"flex", flexWrap:"wrap"}}>
                                        {filtered.map(modelo => (
                                            <BoxModel
                                                key={modelo._id}
                                                name={modelo.nome}
                                                color={modelo.color}
                                                description={(modelo.cultura) ? modelo.cultura.nome : ""}
                                                ref={el => boxRefs.current[modelo._id] = el}
                                                remove={(event)=> {
                                                    remover(event, modelo).catch(err=>console.error(err))
                                                }}
                                                open={(event)=> {
                                                    open(event, modelo).catch(err=>console.error(err))
                                                }}
                                                edit={(event)=> {
                                                    edit(event, modelo).catch(err=>console.error(err))
                                                }}
                                                duplicate={(event)=> {
                                                    duplicate(event, modelo).catch(err=>console.error(err))
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>

            {(cadastroFilter && cadastroFilter["modelo_item"] && selected) ?
                <Crud
                    collection={"modelo_item"}
                    view={"modelo_item"}
                    data={dataModeloItem}
                    title={selected.nome}
                    color={selected.color}
                    configuration={itemConfiguration}
                    focus="modelo"
                    preload={["grupo_produto","produto","fornecedor"]}
                /> : <></>}


            <ModalNew
                view={view}
                collection={"modelo"}
                title={titles[view]}
                focus={"nome"}
                editItem={editModelo}
                configuration={configuration}
                show={newModeloModalShow}
                close={newAppModalClose}
            />
        </>
    )

}

export default Modelo