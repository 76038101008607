import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState, forwardRef, useRef, useImperativeHandle} from "react";
import AuthContext from "../../../context/AuthContext";

import Loader from '../../util/Loader';
import {Button, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";

import { Toast } from 'primereact/toast';
import {Checkbox} from "primereact/checkbox";
import SafraContext from "../../../context/SafraContext";

const ModalClearDuplicates = forwardRef((props, ref) => {

    const {view, collection, configuration= {}, action={}} = props;

    const {t} = useTranslation();

    const {user} = useContext(AuthContext);
    const {clearDuplicates} = useContext(SafraContext);

    const [loader, setLoader] = useState({show:false, msg:'', error:false})
    const [showModal, setShowModal] = useState(props.show);

    const [table, setTable] = useState([])

    useImperativeHandle(
        ref,
        () => ({
            setLoader(show, msg, error) {
                setLoader({show, msg, error})
            }
        }),
    )

    const inputRefs = useRef({});
    const toast = useRef(null);

    useEffect(()=>{
        setShowModal(props.show);
    },[props.show])

    useEffect(() => {
        if(configuration){
            setTable((configuration.table) ? configuration.table : []);
        }
    }, [configuration]);

    const {
        register,
        control,
        watch,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm();

    const onShow = ()=>{
        if(props.onShow) props.onShow()
    }
    const onExited = ()=>{
        reset();
        setLoader({show:false, msg:'', error: false})
        setSelectedKeyField([])
        if(props.onClose) props.onClose();
    }

    const onSubmit = (data, e) => {
        //console.log(data);

        const limpar = async ()=>{
            return (action && action.clearDuplicates) ? await action.clearDuplicates(view, collection, selectedKeyField) : await clearDuplicates(view, collection, selectedKeyField);
        }

        setLoader({show:true, msg:t('salvando'), error: false})
        limpar().then((rs)=>{
            //console.log(rs);
            setLoader({show:false, msg:'', error: false})
            if(!rs || rs.error){
                toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_salvar'), life: 2000});
                return;
            }
            if(props.onSave){
                props.onSave(rs);
            }
            props.close();
        }).catch(err=>{
            setLoader({show:false, msg:'', error: false})
            console.error(err);
        })

    }

    const [selectedKeyField, setSelectedKeyField] = useState([]);
    const onSelectedKeyFieldChange = (e) => {
        let _selectedCategories = [...selectedKeyField];
        if (e.checked) _selectedCategories.push(e.value);
        else _selectedCategories = _selectedCategories.filter(field => field !== e.value);
        setSelectedKeyField(_selectedCategories);
    };

    return (
        <>
            <Modal
                show={showModal}
                onExited={onExited}
                onShow={onShow}
                animation={true}
                dialogClassName="modal-xs"
            >
                <Loader show={loader.show} msg={loader.msg} type="modal" />
                <Toast ref={toast} appendTo={"self"} position="bottom-center" />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header className="bg-light p-2 pl-3">
                        <span className="font-weight-semibold">{t('msg_limpar_duplicados')}</span>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {table.map((item, index) => {
                                if (item.field.startsWith("_")) {
                                    return false;
                                }
                                return (
                                    <div key={"field-" + index} style={{display: "flex"}} className="mb-1">
                                        <Checkbox
                                            className="pt-1"
                                            inputId={item.field}
                                            value={item.field}
                                            onChange={onSelectedKeyFieldChange}
                                            checked={selectedKeyField.some((v) => v === item.field)}/>

                                        <div className="ml-2 mr-2 pt-1" style={{width: "200px"}}>{item.title}</div>
                                    </div>
                                )
                            })}
                            <span className="font-size-xs">* {t('info_selecione_campo_chave')}</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button type="submit" variant="primary">{t('limpar')}</Button>
                        <Button variant="light" onClick={props.close}>{t('fechar')}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )

})

export default ModalClearDuplicates