import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState, forwardRef, useRef, useImperativeHandle} from "react";
import AuthContext from "../../../context/AuthContext";
import SafraContext from "../../../context/SafraContext";

import Loader from '../../util/Loader';
import {Button, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";

import { Toast } from 'primereact/toast';
import FormField from "../../util/FormField";

const ModalNew = forwardRef((props, ref) => {

    const {view, collection, title, color, focus, configuration= {}, validation=async (item)=>{return {}}, action={}} = props;

    const {t} = useTranslation();

    const {user} = useContext(AuthContext);
    const {salvarCadastro, updateCadastro, existsCadastro, existsPKCadastro, getStateCollection, cadastroConfigurationTableMap, cadastroConfigurationFKMap} = useContext(SafraContext);

    const [loader, setLoader] = useState({show:false, msg:'', error:false})
    const [showModal, setShowModal] = useState(props.show);
    const [configMap, setConfigMap] = useState({});
    const [table, setTable] = useState([]);
    const [colSize, setColSize] = useState([]);

    const [editItem, setEditItem] = useState();

    let alreadyInit = {}

    useImperativeHandle(
        ref,
        () => ({
            setLoader(show, msg, error) {
                setLoader({show, msg, error})
            }
        }),
    )

    const inputRefs = useRef({});
    const toast = useRef(null);

    useEffect(() => {
        setEditItem(props.editItem);
    }, [props.editItem]);

    useEffect(()=>{
        setShowModal(props.show);
    },[props.show])

    useEffect(()=>{
        if(showModal){
            for(const name of Object.keys(cadastroConfigurationTableMap[view] || {})){
                const fieldConfig = cadastroConfigurationTableMap[view][name];
                if(editItem && editItem[fieldConfig.field]){
                    if(fieldConfig._type === "reference"){
                        setValue(fieldConfig.field, {value:editItem[fieldConfig.field]._id, label:editItem[fieldConfig.field][fieldConfig._crud_label]});
                    }else{
                        setValue(fieldConfig.field, editItem[fieldConfig.field])
                    }
                }
                if(fieldConfig._type === "reference" && fieldConfig.hasOwnProperty("_filter") && inputRefs.current[name]){
                    const options = inputRefs.current[name].getOptions();
                    if (fieldConfig && fieldConfig._filter && options) {
                        if(editItem && editItem[fieldConfig.field]){
                            handleSelectChange(name, {value:editItem[fieldConfig.field]._id, label:editItem[fieldConfig.field][fieldConfig._crud_label]}, 'showModal editItem');
                        }else{
                            handleSelectChange(name, options[0], 'showModal');
                        }
                    }
                }
            }
            alreadyInit = null;
        }
    },[showModal, editItem])

    useEffect(() => {
        if(configuration){
            setTable((configuration.table) ? configuration.table : []);
        }
    }, [configuration]);

    useEffect(() => {
        const map = {}
        let ncols = 1;
        for(const [,item] of table.entries()){
            map[item.field] = item;
            if(item._col && item._col > 1 && item._col > ncols) {
                ncols = item._col;
            }
        }
        setColSize(ncols);
        setConfigMap(map);
    }, [table]);

    const {
        register,
        control,
        watch,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm();

    const salvarAPI = async (entity)=>{
        if(editItem && editItem._id){
            delete entity._id;
            return (action && action.update) ? await action.update(view, collection, editItem._id, entity) : await updateCadastro(view, collection, editItem._id, entity);
        }else{
            return (action && action.salvar) ? await action.salvar(view, collection, entity) : await salvarCadastro(view, collection, entity)
        }
    }

    const onShow = ()=>{
        setFocus(focus, { shouldSelect: true })
        if(props.onShow) props.onShow()
    }
    const onExited = ()=>{
        reset();
        setLoader({show:false, msg:'', error: false})
        if(props.onClose) props.onClose();
    }

    const onSubmit = (data, e) => {

        //console.log(data); return;

        const salvar = async ()=>{

            let hasError = false;
            let pkValues = {}
            for(const field of Object.keys(configMap)){
                const conf = configMap[field];

                let value = "";
                if(conf._type === 'list' || conf._type === 'reference'){
                    if(conf._multiple){
                        value = (data[field] && Array.isArray(data[field])) ? data[field].join(",") : [];
                    }else{
                        value = (data[field]) ? data[field].value : "";
                    }
                }else{
                    value = data[field]+"";
                }

                let editItemValue = "";
                if(editItem){
                    if(conf._type === 'reference'){
                        editItemValue = (editItem[field]) ? editItem[field]._id : "";
                    }else{
                        editItemValue = editItem[field]+"";
                    }
                }

                if(conf.hasOwnProperty('_mandatory') && conf._mandatory && (!value || value.trim() === '')){
                    setError(field, { type:"manual", message:t('info_campo_vazio',{campo:t(field)}) })
                    hasError = true;
                }

                if(conf.hasOwnProperty('_unique') && conf._unique && (!editItem || editItemValue.trim() !== value.trim()) ){
                    const rs = (action && action.exists) ? await action.exists(view, collection, field, value.trim()) : await existsCadastro(view, collection, field, value.trim());
                    if(rs.length > 0){
                        setError(field, { type:"manual", message:t('info_valor_existe') })
                        hasError = true;
                    }
                }

                if(configuration && configuration.pk && configuration.pk.includes(field) && (!editItem || editItemValue.trim() !== value.trim())){
                    pkValues[field] = value;
                }
            }


            if(Object.keys(pkValues).length > 0){
                const rs = (action && action.existsPK) ? await action.existsPK(view, collection, pkValues) : await existsPKCadastro(view, collection, pkValues);
                if(rs.length > 0){
                    Object.keys(pkValues).forEach((field)=>{
                        setError(field, { type:"manual", message:t('info_valor_existe') })
                    })
                    hasError = true;
                }
            }

            const errorMap =  await validation(data);
            if(errorMap && Object.keys(errorMap).length > 0){
                for(const key of Object.keys(errorMap)){
                    setError(key, { type:"manual", message:errorMap[key] })
                }
                hasError = true;
            }

            if(hasError) return false;

            const entity = {}
            for(const key of Object.keys(data)){
                const conf = configMap[key];
                if(conf._type === 'list' || conf._type === 'reference'){
                    if(conf._multiple){
                        entity[key] = (data[key] && Array.isArray(data[key])) ? data[key] : [];
                    }else{
                        entity[key] = (data[key]) ? data[key].value : "";
                    }
                }else{
                    entity[key] = data[key];
                }
            }
            return await salvarAPI(entity)
        }

        setLoader({show:true, msg:t('salvando'), error: false})
        salvar().then((rs)=>{
            setLoader({show:false, msg:'', error: false})
            if(!rs || rs.error){
                toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_salvar'), life: 2000});
                return;
            }
            if(props.onSave){
                props.onSave(rs);
            }
            props.close();
        }).catch(err=>{
            setLoader({show:false, msg:'', error: false})
            console.error(err);
        })

    }

    const handleSelectChange = (name, selectedValue, origin)=>{
        //console.log(name, selectedValue, origin, alreadyInit);
        if(alreadyInit && alreadyInit[name]) return;
        if(alreadyInit) alreadyInit[name] = true;

        const configMap = cadastroConfigurationTableMap[view];
        if(!configMap) return;

        const fieldConfig = cadastroConfigurationTableMap[view][name];


        if(fieldConfig && fieldConfig._filter){

            const refConfig = cadastroConfigurationTableMap[view][fieldConfig._filter]
            const selectedItem = getStateCollection(name).filter((item)=>{
                return (item._id === selectedValue.value);
            })[0]

            let filteredOptions = [];
            if(cadastroConfigurationFKMap[fieldConfig._filter] && cadastroConfigurationFKMap[fieldConfig._filter][name]){
                //console.log('1:N', fieldConfig);
                filteredOptions = getStateCollection(fieldConfig._filter).filter((item)=>{
                    if(fieldConfig._filter_field){
                        return !!(item[name] && selectedValue && item[name]._id === selectedItem[fieldConfig._filter_field]);
                    }else{
                        return !!(item[name] && selectedValue && item[name]._id === selectedValue.value);
                    }
                }).map((entry) => {
                    return {label: entry[refConfig._crud_label], value: entry._id}
                });

            }else if(cadastroConfigurationFKMap[name] && cadastroConfigurationFKMap[name][fieldConfig._filter]){
                //console.log('N:1', fieldConfig)
                if(selectedItem && selectedItem[fieldConfig._filter]){
                    filteredOptions = [
                        {label: selectedItem[fieldConfig._filter][fieldConfig._crud_label], value: selectedItem[fieldConfig._filter]._id}
                    ]
                }
            }

            if(inputRefs.current[fieldConfig._filter]){
                //console.log('filter', fieldConfig._filter);
                const fieldFilterConfig = cadastroConfigurationTableMap[view][fieldConfig._filter];
                if(!fieldFilterConfig) return;
                if(fieldFilterConfig._multiple){
                    inputRefs.current[fieldConfig._filter].setOptions(filteredOptions);
                    setValue(fieldConfig._filter, (filteredOptions[0]) ? [filteredOptions[0].value] : []);
                }else{
                    inputRefs.current[fieldConfig._filter].setOptions(filteredOptions);
                    setValue(fieldConfig._filter, (filteredOptions[0]) ? filteredOptions[0] : "");
                    handleSelectChange(fieldConfig._filter, (filteredOptions[0]) ? filteredOptions[0] : "", 'recursive');
                }
            }
        }

    }

    return (
        <>
            <Modal
                show={showModal}
                onExited={onExited}
                onShow={onShow}
                animation={true}
                dialogClassName={(colSize <= 1) ? "" : "modal-lg"}
            >
                <Loader show={loader.show} msg={loader.msg} type="modal" />
                <Toast ref={toast} appendTo={"self"} position="bottom-center" />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header className="p-2 pl-3" style={(color) ? {backgroundColor:"#"+color+"2b"} : {backgroundColor:"#f0f2f5"}}>
                        <span className="font-weight-semibold">{(title) ? title : t('novo')}</span>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className={(colSize <= 1) ? "col-md-12" : "col-md-6"}>
                                {table.map((item, index) => {
                                    if (item.field.startsWith('_')) return true;
                                    if (item._col && item._col > 1) return true;

                                    return (
                                        <FormField
                                            key={"form-field-"+index}
                                            collection={collection}
                                            index={index}
                                            tableItem={item}
                                            handleSelectChange={handleSelectChange}
                                            inputRef={(el) => inputRefs.current[item.field] = el}
                                            register={register} errors={errors} control={control} setValue={setValue}
                                        />
                                    )

                                })}
                            </div>
                            <div className="col-md-6" style={(colSize <= 1) ? {display:"none"}:{display:"block"}}>
                                {table.map((item, index) => {
                                    if (item.field.startsWith('_')) return true;
                                    if (!item._col || item._col < 2) return true;

                                    return (
                                        <FormField
                                            key={"form-field-"+index}
                                            collection={collection}
                                            index={index}
                                            tableItem={item}
                                            handleSelectChange={handleSelectChange}
                                            inputRef={(el) => inputRefs.current[item.field] = el}
                                            register={register} errors={errors} control={control} setValue={setValue}
                                        />
                                    )

                                })}
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">{t('salvar')}</Button>
                        <Button variant="light" onClick={props.close}>{t('fechar')}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )

})

export default ModalNew