import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar'
import {forwardRef, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { NumericFormat, numericFormatter } from 'react-number-format';

const BarChart = forwardRef(({config, layout="horizontal", marginLeft, marginBottom}, ref) => {

    const {t} = useTranslation();

    const [mLeft, setMLeft] = useState(20)

    useEffect(() => {
        if(config && config.data){
            let size = 0;
            for(const [,item] of config.data.entries()){
                if(item[config.indexBy] && item[config.indexBy].length > size){
                    size = item[config.indexBy].length
                }
            }
            setMLeft(size*7);
        }
    }, [config]);

    return (
        <>
            <ResponsiveBar
                data={(config.data) ? config.data : []}
                indexBy={(config.indexBy) ? config.indexBy : "_id"}

                keys={(config.keys) ? config.keys : []}
                colors={(config.colors) ? config.colors : []}

                groupMode="grouped"
                margin={{top: 15, right: 140, bottom: (marginBottom) ? marginBottom : 100, left:(marginLeft) ? marginLeft : mLeft}}
                padding={0.3}
                layout={layout}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}

                tooltip={function(e){
                    return (
                        <div className="font-size-xs p-2 bg-light"><span className="font-weight-bold">{e.label}: </span><NumericFormat value={e.value} decimalSeparator="," thousandSeparator="." displayType="text" decimalScale={2} /></div>
                    )
                }}
                enableLabel={false}

                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 6,
                    tickPadding: 5,
                    tickRotation: 40,
                    legend: (config.x) ? config.x : "",
                    legendPosition: 'middle',
                    legendOffset: 80,
                    truncateTickAt: 0,
                    format: (value)=> {
                        if(layout === 'horizontal'){
                            return numericFormatter(value+"", {
                                thousandSeparator: ".",
                                decimalSeparator: ",",
                                decimalScale: 2,
                                displayType: "text"
                            });
                        }else{
                            return value
                        }
                    }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 12,
                    tickRotation: 0,
                    legend: (config.y) ? config.y: "",
                    legendPosition: 'middle',
                    legendOffset: (marginLeft) ? marginLeft : -mLeft+30,
                    truncateTickAt: 0,
                    format: (value)=> {
                        if(layout === 'vertical'){
                            return numericFormatter(value+"", {
                                thousandSeparator: ".",
                                decimalSeparator: ",",
                                decimalScale: 2,
                                displayType: "text"
                            });
                        }else{
                            return value
                        }
                    }
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
            />

        </>
    )
})

export default BarChart