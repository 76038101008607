import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "./../../../context/AuthContext";
import CrudAdm from "./CrudAdm";
import ControlContext from "../../../context/ControlContext";
import SafraContext from "../../../context/SafraContext";

import {Toast} from "primereact/toast";


const Usuario = ({view}) => {

    const {t} = useTranslation();
    const {user, clientID} = useContext(AuthContext);
    const {api} = useContext(SafraContext);

    const toast = useRef(null);

    const [configuration, setConfiguration] = useState({});

    useEffect(()=>{

        const load = async ()=>{
            const grupos = await api(view, 'grupo', "listar");

            const grupoValues = [];
            for(const [, grupo] of grupos.entries()){
                grupoValues.push({value:grupo._id, label:grupo.nome})
            }

            setConfiguration({
                table: [
                    {field: '_id', title: t("id"), type: "id"},
                    {field: 'login', title: t("login"), type: "text", mandatory: true, unique: true},
                    {field: 'senha', title: t("senha"), type: "password", mandatory: true},
                    {field: 'nome', title: t("nome"), type: "text", mandatory: true},
                    {
                        field: 'grupo', title: t("grupo"), type: "list", _empty: true,
                        "values": grupoValues
                    },
                    {
                        field: 'tipo', title: t("tipo"), type: "list", _empty: false,
                        "values": [
                            {value: "user", label: t('Comum')},
                            {value: "adm", label: t('administrador')}
                        ]
                    },
                    {field: 'email', title: t("email"), type: "text"}
                ]
            })
        }

        load().catch(err=>{
            toast.current.show({severity:'error', summary: t("usuario"), detail:t('error_config'), life: 2000});
        })



    },[])

    const validation = async (item)=>{
        const errors = {}



        return errors;
    }

    return (
        <>
            <Toast ref={toast} appendTo={"self"} position="top-center" />
            <CrudAdm id={"usuario"} view={view} configuration={configuration} validation={validation} focus={"login"} />
        </>
    )

}

export default Usuario