import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "../../context/AuthContext";
import {tempo as tempoControl}  from "../../control/Tempo";
import Climate from "../util/Climate";


const Painel = ({view}) => {

    const {t} = useTranslation();

    const {user} = useContext(AuthContext);
    const [location, setLocation] = useState()

    useEffect(()=>{
        const getInfos = async ()=>{
            //const {ip} = await tempoControl.ipfy();
            //const origin = await tempoControl.ipOrigin(ip);
            //setLocation({country:origin.countryCode, state:origin.region, city:origin.city});
        }
        if(user){
            getInfos().catch(err=>{
                console.error(err)
            })
        }
        return ()=>{}
    },[user])

    return (
        <></>
    )

}

export default Painel