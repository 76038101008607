import GenericControl from "./GenericControl";

export default class AuthControl extends GenericControl{

    /**
     * @typedef {Object} UserSession
     * @property {string} exp
     * @property {string} iat
     * @property {{}} menu
     * @property {string} token
     * @property {{}} user
     **/


    /**
     * @param opts {{}}
     * @param opts.login {string}
     * @param opts.senha {string}
     * @returns {Promise<{statusText: string, error, status: number}|any>}
     */
    async signIn(opts){
        const url = this.api+'/__auth/login';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({...opts}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-clientid':this.client,
                'x-access-token':'',
                'x-access-userid':''
            }
        })
        if(response.ok){
            const rs = await response.json();
            this.emit('signIn', rs)
            return rs;
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    /**
     * @param userid {string}
     * @param token {string}
     * @returns {Promise<{statusText: string, error, status: number}|any>}
     */
    async validate(userid, token){
        const url = this.api+'/__auth/validate';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-clientid':this.client,
                'x-access-token': token,
                'x-access-userid':userid
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async validateClient(){
        const url = this.api+'/__auth/client/validate/'+this.client;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    /**
     * @param user {{}}
     * @returns {Promise<*>}
     */
    async signOut(user){
        this.emit('signOut', user)
        return user;
    }
}

export const auth = new AuthControl()