import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import $ from "jquery";
import {useTranslation} from "react-i18next";
import AuthContext from "./../../context/AuthContext";
import {Link} from "react-router-dom";

const LeftSidebar = forwardRef((props, ref) => {

    const {menu, view} = props;
    const {t} = useTranslation();
    const {user, client} = useContext(AuthContext);

    useImperativeHandle(
        ref,
        () => ({
            test() {}
        }),
    )

    const divRef = useRef();

    useEffect(()=>{
        $('.sidebar-left-mobile-expand').unbind('click').click(function (e){
            e.preventDefault();
            const $sidebar = $(this).parents('.sidebar');
            const sidebarFullscreenClass = 'sidebar-fullscreen';
            if(!$sidebar.hasClass(sidebarFullscreenClass)) {
                $sidebar.addClass(sidebarFullscreenClass);
            }else {
                $sidebar.removeClass(sidebarFullscreenClass);
            }
        });
        $('.sidebar-mobile-secondary-toggle').on('click', function (e) {
            e.preventDefault();
            $('body').toggleClass('sidebar-mobile-secondary').removeClass('sidebar-mobile-main sidebar-mobile-right');

            // Fullscreen mode
            const $sidebar = $('.sidebar-secondary');
            if($sidebar.hasClass('sidebar-fullscreen')) {
                $sidebar.removeClass('sidebar-fullscreen');
            }
        });

        const navigationSidebar = function() {

            // Define default class names and options
            var navClass = 'nav-sidebar',
                navItemClass = 'nav-item',
                navItemOpenClass = 'nav-item-open',
                navLinkClass = 'nav-link',
                navSubmenuClass = 'nav-group-sub',
                navSubmenuExpandedClass = 'nav-group-sub-visible',
                navScrollSpyClass = 'nav-scrollspy',
                navSlidingSpeed = 250;

            // Configure collapsible functionality
            $('.' + navClass + ':not(.' + navScrollSpyClass + ')').each(function() {
                $(this).find('.' + navItemClass).has('.' + navSubmenuClass).children('.' + navItemClass + ' > ' + '.' + navLinkClass).not('.disabled').on('click', function (e) {
                    e.preventDefault();

                    // Simplify stuff
                    var $target = $(this);
                    var $navSidebarMini = $('.sidebar-main-resized:not(.sidebar-mobile-expanded)').find('.' + navClass).children('.' + navItemClass);

                    // Collapsible
                    if($target.parent('.' + navItemClass).hasClass(navItemOpenClass)) {
                        $target.parent('.' + navItemClass).not($navSidebarMini).removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
                    }
                    else {
                        $target.parent('.' + navItemClass).not($navSidebarMini).addClass(navItemOpenClass).children('.' + navSubmenuClass).slideDown(navSlidingSpeed);
                    }

                    // Accordion
                    if ($target.parents('.' + navClass).data('nav-type') == 'accordion') {
                        $target.parent('.' + navItemClass).not($navSidebarMini).siblings(':has(.' + navSubmenuClass + ')').removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
                    }
                });
            });

            // Disable click in disabled navigation items
            $(document).on('click', '.' + navClass + ' .disabled', function(e) {
                e.preventDefault();
            });
        };
        navigationSidebar();

    },[])

    return (
        <>
            <div ref={divRef} className="sidebar sidebar-light bg-white sidebar-secondary sidebar-expand-md"
                 style={{
                     overflowY: 'auto',
                     borderBottom: '1px solid rgba(0, 0, 0, 0.125)'
                 }}>
                <div className="sidebar-mobile-toggler text-center">
                    <a href="#" className="sidebar-mobile-secondary-toggle">
                        <i className="icon-arrow-left8"></i>
                    </a>
                    <span className="font-weight-semibold"></span>
                    <a href="#" className="sidebar-mobile-expand sidebar-left-mobile-expand">
                        <i className="icon-screen-full"></i>
                        <i className="icon-screen-normal"></i>
                    </a>
                </div>

                <div className="sidebar-content">
                    <div className="sidebar-section">
                        <ul className="nav nav-sidebar" data-nav-type="accordion">
                            <li className="nav-item-header">
                                <div className="text-uppercase font-size-xs line-height-xs">Menu</div>
                                <i className="icon-menu"></i>
                            </li>

                            {(menu) ?
                                <>
                                    {
                                        Object.keys(menu).map((key) => {
                                            const item = menu[key];
                                            if (item.children) {
                                                return (
                                                    <li key={key} className="nav-item nav-item-submenu">
                                                        <a href="#" className="nav-link"><i className={item.icon}></i> <span>{item.title}</span></a>
                                                        <ul className="nav nav-group-sub" data-submenu-title={item.title}>
                                                            {
                                                                Object.keys(item.children).map((subKey) => {
                                                                    const subItem = item.children[subKey];
                                                                    return (
                                                                        <li key={subKey} className="nav-item">
                                                                            <Link className="nav-link" to={"/"+client._id+"/"+view+"/"+ subKey}><i className="icon-diamond3 text-green"></i> {subItem.title}</Link>
                                                                            {/*<a href="#" onClick={() => handleItemClick(subKey)} className="nav-link"><i className="icon-diamond3 text-green"></i> {subItem.title}</a>*/}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </li>
                                            )
                                            }else{
                                                return (
                                                    <li key={key} className="nav-item">
                                                        <Link className="nav-link" to={"/"+client._id+"/"+view+"/"+key}><i className="icon-diamond3 text-green"></i> {item.title}</Link>
                                                        {/*<a href="#" onClick={() => handleItemClick(key)} className="nav-link"><i className="icon-diamond3 text-green"></i> {item.title}</a>*/}
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                </> : <></>}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
})
export default LeftSidebar