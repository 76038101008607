import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "../../../context/AuthContext";
import SafraContext from "../../../context/SafraContext";
import {Toast} from "primereact/toast";
import Loader from "../../util/Loader";
import CalendarTimeline from "../../charts/CalendarTimeline";
import moment from "moment";
import Common from "../../../util/common";
import RHFSelect from "../../forms/RHFSelect";
import {useForm} from "react-hook-form";
import ControlContext from "../../../context/ControlContext";


const Cronograma = ({view}) => {

    const {t} = useTranslation();

    const {user, clientID} = useContext(AuthContext);
    const {titles} = useContext(ControlContext);
    const {listarCadastro, getStateCollection} = useContext(SafraContext);

    const toast = useRef(null);

    const [loader, setLoader] = useState({show:true, msg:"", error:false});

    const [data, setData] = useState([]);
    const [rows, setRows] = useState([])

    const FKs = ["safra", "cultura", "variedade", "sub_unidade", "area"]
    const [filterSelectList, setFilterSelectList] = useState({});
    const [selectedFilter, setSelectedFilter] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const selectRef = useRef({});

    useEffect(() => {
        const init = async ()=>{
            await listarCadastro(view, "planejamento");
        }
        setLoader({show:true, msg:'', error:false})
        init().then(()=>{
            setLoader({show:false, msg:'', error:false})
        }).catch(err=>{
            setLoader({show:false, msg:'', error:true})
            console.error(err);
        })
        return () => {
            setLoader({show:true, msg:'', error:false})
        };
    }, []);

    useEffect(()=>{
        const collection = getStateCollection("planejamento");
        //console.log("planejamento", "Atualizando Dados", collection);
        if(collection){
            setData(collection);
            const listMap = buildFilterSelect(collection);
            setFilterSelectList(listMap);
        }
    },[getStateCollection("planejamento")])

    const {
        register,
        control,
        watch,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm();


    const buildFilterSelect = (data)=>{
        const _map = {}
        for(const [, fk] of FKs.entries()){
            _map[fk] = {};
        }

        const buildMap = (name, item, mapIds)=>{
            if(!_map[name][item._id]) _map[name][item._id] = {...item, _children:[]}
            for(const [, key] of FKs.entries()){
                if(key === name) continue;
                if(!_map[name][item._id]["_"+key]) _map[name][item._id]["_"+key] = [];
                if(!_map[name][item._id]["_"+key].includes(mapIds[key]._id)){
                    _map[name][item._id]["_"+key].push(mapIds[key]._id)
                }
            }
        }

        for(const [, item] of data.entries()){
            const mapIds = {
                safra: item.safra,
                variedade: item.variedade,
                cultura:item.cultura,
                sub_unidade: item.sub_unidade,
                area:item.area
            }
            buildMap('safra', item.safra, mapIds);
            buildMap('sub_unidade', item.sub_unidade, mapIds);
            buildMap('cultura', item.cultura, mapIds);
            buildMap('variedade', item.variedade, mapIds);
            buildMap('area', item.area, mapIds);
        }

        const listMap= {}
        for(const [, fk] of FKs.entries()){
            listMap[fk] = Object.values(_map[fk])
        }

        return listMap;
    }

    const handleChangeFilter = (name, selectedValue)=>{
        setSelectedFilter((prevState)=>{
            if(!selectedValue){
                const newState = {...prevState};
                delete newState[name];
                return newState;
            }
            return {...prevState, [name]:selectedValue}
        })
    }

    useEffect(() => {
        if(data && selectedFilter){
            const dataCopy = JSON.parse(JSON.stringify(data));
            const _filteredData = dataCopy.filter((item)=>{
                return !(
                    (selectedFilter.safra && item.safra._id !== selectedFilter.safra.value) ||
                    (selectedFilter.sub_unidade && item.sub_unidade._id !== selectedFilter.sub_unidade.value) ||
                    (selectedFilter.area && item.area._id !== selectedFilter.area.value) ||
                    (selectedFilter.cultura && item.cultura._id !== selectedFilter.cultura.value) ||
                    (selectedFilter.variedade && item.variedade._id !== selectedFilter.variedade.value)
                )
            });
            const listMap = buildFilterSelect(_filteredData);

            const _rows = [
                [
                    { type: "string", id: "cultura_variedade" },
                    { type: "string", id: "area" },
                    { type: 'string', role: 'tooltip' },
                    { type: 'string', id: 'style', role: 'style' },
                    { type: "date", id: "start" },
                    { type: "date", id: "end" },
                ]
            ]

            for(const [,item] of _filteredData.entries()){
                if(item.plantio && item.plantio !== ""){
                    const key = item.cultura.nome+" - "+item.variedade.variedade
                    _rows.push([
                        key,
                        item.area.descricao,
                        '<div class="custom-tooltip-timeline"><div><b>'+t('ciclo')+"</b>: "+item.ciclo+"</div><div><b>"+t('periodo')+"</b>: "+moment(item.plantio).format("DD/MM/YYYY")+" - "+moment(item.colheita).format("DD/MM/YYYY")+"</div></div>",
                        (item.modelo && item.modelo.color) ? "#"+item.modelo.color : "#006400",
                        moment(item.plantio).toDate(),
                        moment(item.colheita).toDate()
                    ]);
                }
            }
            setRows(_rows);

            setFilterSelectList(listMap);
            setFilteredData(_filteredData);
        }
    }, [data, selectedFilter]);


    return (
        <>
            <Loader type="content" show={loader.show} msg={loader.msg}/>
            <Toast ref={toast} appendTo={"self"} position="bottom-center"/>
            <div className="row" style={{height: "100%"}}>
                <div className="col-md-12">
                    <div className="card mb-1" style={{height: "100%"}}>
                        <div className={"card-header border-bottom p-2"} style={{backgroundColor: "#f0f2f5"}}>
                            <span className="card-title font-weight-bold font-size-lg">{titles[view]}</span>
                        </div>
                        <div className="border-bottom p-1 w-100">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-2 text-right font-weight-bold">{t('safra')}</label>
                                        <div className="col-lg-10">
                                            <RHFSelect
                                                ref={el => selectRef.current["safra"] = el}
                                                name={"safra"}
                                                placeholder={t('selecione')}
                                                control={control}
                                                options={(filterSelectList && filterSelectList["safra"]) ? filterSelectList["safra"].map((item) => {
                                                    return {label: item.nome, value: item._id}
                                                }) : []}
                                                handleChange={handleChangeFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-2 text-right font-weight-bold">{t('sub_unidade')}</label>
                                        <div className="col-lg-10">
                                            <RHFSelect
                                                ref={el => selectRef.current["sub_unidade"] = el}
                                                name={"sub_unidade"}
                                                placeholder={t('selecione')}
                                                control={control}
                                                options={(filterSelectList && filterSelectList["sub_unidade"]) ? filterSelectList["sub_unidade"].map((item) => {
                                                    return {label: item.nome, value: item._id}
                                                }) : []}
                                                handleChange={handleChangeFilter}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <label className="col-form-label col-lg-2 text-right font-weight-bold">{t('area')}</label>
                                        <div className="col-lg-10">
                                            <RHFSelect
                                                ref={el => selectRef.current["area"] = el}
                                                name={"area"}
                                                placeholder={t('selecione')}
                                                control={control}
                                                options={(filterSelectList && filterSelectList["area"]) ? filterSelectList["area"].map((item) => {
                                                    return {label: item.descricao, value: item._id}
                                                }) : []}
                                                handleChange={handleChangeFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">

                                    <div className="row">
                                        <label className="col-form-label col-lg-2 text-right font-weight-bold">{t('cultura')}</label>
                                        <div className="col-lg-10">
                                            <RHFSelect
                                                ref={el => selectRef.current["cultura"] = el}
                                                name={"cultura"}
                                                placeholder={t('selecione')}
                                                control={control}
                                                options={(filterSelectList && filterSelectList["cultura"]) ? filterSelectList["cultura"].map((item) => {
                                                    return {label: item.nome, value: item._id}
                                                }) : []}
                                                handleChange={handleChangeFilter}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <label className="col-form-label col-lg-2 text-right font-weight-bold">{t('variedade')}</label>
                                        <div className="col-lg-10">
                                            <RHFSelect
                                                ref={el => selectRef.current["variedade"] = el}
                                                name={"variedade"}
                                                placeholder={t('selecione')}
                                                control={control}
                                                options={(filterSelectList && filterSelectList["variedade"]) ? filterSelectList["variedade"].map((item) => {
                                                    return {label: item.variedade, value: item._id}
                                                }) : []}
                                                handleChange={handleChangeFilter}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={"card-header border-bottom p-2"} style={{backgroundColor: "#f0f2f5"}}></div>
                        <div className="card-body pt-2" style={{height: "100%"}}>
                            {(rows && rows.length > 1) ? <CalendarTimeline data={rows}/> : t('msg_nenhum_registro')}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}

export default Cronograma