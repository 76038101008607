import {useParams} from "react-router-dom";
import {AuthContextProvider} from '../context/AuthContext'
import {ControlContextProvider} from "../context/ControlContext";
import {SafraContextProvider} from "../context/SafraContext";
import Main from "./Main";

const Container = ({}) => {
    const {clientid, view="painel", view2} = useParams();

    return (
        <>
            <ControlContextProvider clientid={clientid}>
                <AuthContextProvider clientid={clientid}>
                    <SafraContextProvider>
                        <Main view={view} view2={view2}/>
                    </SafraContextProvider>
                </AuthContextProvider>
            </ControlContextProvider>
        </>
    )
}
export default Container