import 'tabulator-tables/dist/css/tabulator.min.css';
import 'tabulator-tables/dist/css/tabulator_simple.min.css';

import "primereact/resources/themes/lara-light-blue/theme.css";
import "primeicons/primeicons.css";

import './styles/bootstrap.css';
import './styles/bootstrap_limitless.css';
import './styles/colors.css';
import './styles/components.css';
import './styles/layout.css';
import './styles/loading.bars.css';
import './styles/loading.css';
import './styles/animate.min.css';
import './styles/icons/icomoon/styles.min.css';
import './styles/globals.css';
import './styles/tabulator.xs.css';

import React, {useEffect} from "react";
import {ConfigContextProvider} from "./context/ConfigContext";
import {addLocale, PrimeReactProvider } from 'primereact/api';

import localPrimereact from './locales/primereact.pt-br.json'


import Hub from "./components/Hub";

const App = props => {
    addLocale('pt-br', localPrimereact['pt-br']);

    const value = {
        locale: 'pt-br'
    };

    return (
        <PrimeReactProvider value={value}>
          <ConfigContextProvider>
              <Hub />
          </ConfigContextProvider>
        </PrimeReactProvider>
    )
}
export default App