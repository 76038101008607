import {useContext, useEffect, useRef, useState, forwardRef, useImperativeHandle} from "react";
import {Button, Image, Modal, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import $ from 'jquery';

import AuthContext from "../context/AuthContext";
import ConfigContext from "../context/ConfigContext";
import ControlContext from "../context/ControlContext";

const Menu = forwardRef((props, ref) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {version} = useContext(ConfigContext);
    const {user, signOut, signIn, sleep, client, clientID} = useContext(AuthContext)
    const {setLoadingFull, errorMap, leftSidebar, rightSidebar, menu, setMenu} = useContext(ControlContext);

    const $login = useRef('login')
    const $password = useRef('password')

    const [loading, setLoading] = useState(true)

    const [showLoginModal, setShowLoginModal] = useState(false);
    const handleLoginClose = () => setShowLoginModal(false);
    const handleLoginEntered = ()=> {$login.current.focus()}

    const [loaderLogin, setLoaderLogin] = useState({show:false, msg:'', error:false})

    useImperativeHandle(
        ref,
        () => ({
            isLoading(){
                return loading
            }
        }),
    )

    const login = async (event)=>{
        event.preventDefault();
        setLoaderLogin({show:true, msg:t('autenticando'), error:false})
        await sleep(500);
        const data = {
            login: $login.current.value,
            senha: $password.current.value
        }
        const rs = await signIn(data).catch(err=>{
            console.log(err);
            setLoaderLogin({show:false, msg:t('error_server'), error:true})
        });
        if(rs && rs.error){
            setLoaderLogin({show:false, msg:errorMap[rs.error], error:true})
        }else{
            setLoaderLogin({show:false, msg:'', error:false})
        }
    }


    const logout = async (event)=>{
        event.preventDefault();
        setLoadingFull(true);
        signOut(user).then(()=>{
            setLoadingFull(false);
            navigate('/'+clientID)
        }).catch(err=>{
            setLoadingFull(false);
        });
    }

    useEffect(()=>{
        if(user && user.menu){
            setMenu(user.menu);
            setLoadingFull(false);
            setShowLoginModal(false);
        }else{
            setShowLoginModal(true);
        }
    },[user])

    useEffect(()=>{
        setLoading(false)
    },[])

    const sidebarMobileLeftToggle = (e)=>{
        e.preventDefault();
        $('body').toggleClass('sidebar-mobile-secondary');
    }
    const sidebarMobileRightToggle = (e)=>{
        e.preventDefault();
        $('body').toggleClass('sidebar-mobile-right');
    }

    return (
        <>
            {(menu && user) ?
                <Navbar expand="md" style={{padding: 0}} className="navbar-sm">
                    <div style={{padding: "8px 15px 5px 15px"}}>
                        <img src={process.env.PUBLIC_URL + "/assets/icons/32x32.png"} alt=""/>
                    </div>
                    <div className="d-md-none">
                        {(leftSidebar) ? <>
                            <button type="button" className="navbar-toggler sidebar-mobile-main-toggle collapsed"
                                    onClick={(e) => sidebarMobileLeftToggle(e)}>
                                <i className="icon-transmission"></i>
                            </button>
                        </> : <></>}
                        <Navbar.Toggle aria-controls="basic-navbar-nav" children={<i className="icon-menu7"></i>}/>
                        {rightSidebar ? <>
                            <button type="button" className="navbar-toggler sidebar-mobile-right-toggle"
                                    onClick={(e) => sidebarMobileRightToggle(e)}>
                                <i className="icon-transmission"></i>
                            </button>
                        </> : <></>}
                    </div>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            {
                                Object.keys(menu).map((key) => {
                                    const item = menu[key]
                                    if (item.children) {
                                        return (
                                            <NavDropdown key={key} bsPrefix="navbar-nav-link text-decoration-none" title={<><i className={item.icon + " mr-2"}></i> {item.title}</>} id="navbarScrollingDropdown">
                                                {Object.keys(item.children).map((subKey) => {
                                                    const subItem = item.children[subKey];
                                                    return (
                                                        <div key={'div-'+subKey}>
                                                            {(subItem.header) ? <NavDropdown.Header key={'header-' + subKey}>{subItem.header}</NavDropdown.Header> : <></>}
                                                            <NavDropdown.Item as={Link} key={subKey} to={'/' + client._id + "/" + subKey}><i className="icon-chevron-right mr-2"></i>{subItem.title} </NavDropdown.Item>
                                                        </div>
                                                    )
                                                })}
                                            </NavDropdown>
                                        )
                                    } else {
                                        return (
                                            <Nav.Item key={key}>
                                                <Link className="navbar-nav-link text-decoration-none" to={'/' + client._id + "/" + key}><i className={item.icon + " mr-2"}></i>{item.title}</Link>
                                            </Nav.Item>
                                        )
                                    }
                                })
                            }
                        </Nav>
                        <Nav className="ml-sm-auto" style={{marginRight:"36px"}}>
                            {(user) ? <>
                                    <NavDropdown bsPrefix="navbar-nav-link text-decoration-none" title={<><i className="icon-file-media mr-2"></i> {user.user.nome}</>} id="navbarScrollingDropdown">
                                        <NavDropdown.Item as={Link} to={'/' + client._id + "/user"}><i className="icon-gear mr-2"></i>{t('configurar')} </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={'/' + client._id + "/"} onClick={(event)=>logout(event)}><i className="icon-switch mr-2"></i>{t('sair')} </NavDropdown.Item>
                                    </NavDropdown>
                                </>
                                : ''}

                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                : ''}

            <Modal
                show={showLoginModal}
                onEntered={handleLoginEntered}
                animation={true}
                dialogClassName="modal-xs"
            >
                <Modal.Body style={{padding: "0.25rem"}}>
                    <div className="d-flex justify-content-center align-items-center" style={{width: '100%'}}>
                        <form className="login-form" onSubmit={login}>
                            <div className="card mb-0 border-0">
                                <div className="card-body">
                                    <div className="text-center">
                                        <Image src={process.env.PUBLIC_URL + "/assets/icons/128x128.png"}
                                               className={loaderLogin.show ? "ld ld-tick" : ""} width={128} height={128}
                                               alt=""/>
                                    </div>
                                    <div style={{height:'30px'}} className={(loaderLogin.error) ? "font-size-xs text-center text-danger font-weight-bold" : "font-size-xs text-center text-muted"}>{loaderLogin.msg}</div>
                                    <div className="form-group form-group-feedback form-group-feedback-left">
                                        <input disabled={loaderLogin.show} type="text" ref={$login} className="form-control" placeholder={t('username')} />
                                        <div className="form-control-feedback">
                                            <i className="icon-user text-muted"></i>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-feedback form-group-feedback-left">
                                        <input disabled={loaderLogin.show} type="password" ref={$password} className="form-control" placeholder={t('password')} />
                                        <div className="form-control-feedback">
                                            <i className="icon-lock2 text-muted"></i>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button disabled={loaderLogin.show} type="submit" className="btn btn-success text-white btn-block">{t('entrar')}</button>
                                    </div>
                                    <div className="text-center">
                                        <span className="badge badge-light mr-1" id="cliente">{client.nome}</span>
                                        <span className="badge badge-light" id="version-login">{version}</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
});

export default Menu