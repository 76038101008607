import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import NotFound from "./views/NotFound";

import Loader from "./util/Loader";

import ControlContext from "../context/ControlContext";
import AuthContext from "../context/AuthContext";
import SafraContext from "../context/SafraContext";
import Denied from "./views/Denied";

const View = (props) => {

    const {view} = props;

    const {t} = useTranslation();
    const {user} = useContext(AuthContext);
    const {views, menuMap} = useContext(ControlContext);
    const {loading:safraLoading} = useContext(SafraContext);

    const [loader, setLoader] = useState({show:true, msg:"", error:false});
    const [denied, setDenied] = useState(true)

    useEffect(()=>{
        if(!safraLoading && menuMap){
            //console.log('Rendering View', denied, view, menuMap);
            if(menuMap[view] || (user && view === 'user')){
                setDenied(false);
            }
            setTimeout(()=>{
                setLoader({show:false, msg:'', error:false})
            },300)
        }
        return () => {
            setLoader({show:true, msg:'', error:false})
        };
    },[safraLoading, menuMap])



    const Empty = (props) => <NotFound {...props} />

    const View = views[view] || Empty;

    return (
        <>
            <Loader type="content" show={loader.show} msg={loader.msg}/>
            <div style={{padding: "15px", width: "100%", height: "93%", overflowY: "auto"}}>
                {(!denied) ? View({view}) : <Denied />}
            </div>

        </>
    )

}

export default View