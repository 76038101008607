import React, {useEffect} from "react";


const Empty = (props) => {

    useEffect(()=>{

    },[])

    return (
        <></>
    )

}

export default Empty