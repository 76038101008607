import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import Loader from "./Loader";
import {useTranslation} from "react-i18next";
import AuthContext from "../../context/AuthContext";
import Common from "../../util/common";
import {Tooltip} from "primereact/tooltip";
import { Button } from 'primereact/button';

const BoxModel = forwardRef((props, ref) => {

    const {t} = useTranslation();

    const {name, description, color, open, remove, edit, duplicate} = props;

    const {user} = useContext(AuthContext);


    const [loader, setLoader] = useState({show:true, msg:t('carregando'), error:false})
    const [selected, setSelected] = useState();


    useImperativeHandle(
        ref,
        () => ({
            setLoader(show, msg, error) {
                setLoader({show, msg, error})
            },
            setSelected(item){
                setSelected(item)
            }
        }),
    )

    const refTooltip = useRef();

    const $box = useRef();

    useEffect(()=>{
        if(user){}
        setLoader({show:false, msg:'', error:false})
    },[user])

    const startOpen = (event)=>{
        if(open) open(event);
    }
    const startDelete = (event)=>{
        if(remove) remove(event);
    }
    const startEdit = (event)=>{
        if(edit) edit(event);
    }
    const startDuplicate = (event)=>{
        if(duplicate) duplicate(event);
    }

    return (
        <div ref={$box}>
            <div className={(selected) ? "box-model box-selected" : "box-model"}>
                <Loader show={loader.show} msg={loader.msg} error={loader.error} type="box"/>
                <div className="box-header" onClick={(event)=> startOpen(event)} style={(color) ? {backgroundColor:Common.hexToRGBA("#"+color,0.3)} : {backgroundColor:'#3682A519'}}>
                    <div className="box-nome font-size-lg font-weight-bold">{name}</div>
                    <div className="box-descricao font-size-xs">{description}</div>
                </div>
                <div className="box-action d-flex" style={{display: "none"}}>
                    <div className="w-50 text-sm-left">
                        <Button icon="icon-pencil" tooltip={t('editar')} tooltipOptions={{ position: 'top' }} className="btn btn-sm btn-light btn-icon mr-1" onClick={(event) => {startEdit(event)}} />
                        <Button icon="icon-copy3" tooltip={t('duplicar')} tooltipOptions={{ position: 'top' }} className="btn btn-sm btn-light btn-icon mr-1" onClick={(event) => {startDuplicate(event)}} />
                    </div>
                    <div className="w-50 text-sm-right">
                        <Button icon="icon-trash" tooltip={t('remover')} tooltipOptions={{ position: 'top' }} className="btn btn-sm btn-light text-danger btn-icon" onClick={(event) => {startDelete(event)}} />
                    </div>
                </div>
                <div className="box-loading">
                    <div className="box-loading-img">
                        <div className="lds-facebook">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default BoxModel