import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {tempo as tempoControl}  from "../../control/Tempo";
import AuthContext from "../../context/AuthContext";
import ConfigContext from "../../context/ConfigContext";
import Loader from "./Loader";

const Climate = forwardRef((props, ref) => {

    const {t} = useTranslation();

    const {location} = props;

    const {user} = useContext(AuthContext);
    const {climatempo} = useContext(ConfigContext);

    const [loader, setLoader] = useState({show:true, msg:"", error:false});

    useImperativeHandle(
        ref,
        () => ({

        }),
    )

    useEffect(()=>{
        if(user){}
    },[user])

    useEffect(() => {
        if(location){
            const load = async ()=>{
                return;
                let locale;
                const localeList =  await tempoControl.localeId(climatempo.token, location.country, location.state, location.city)
                if(localeList && Array.isArray(localeList)){
                    for(const [, local] of localeList.entries()){
                        if(local.name.toLowerCase() === location.city.toLowerCase()){
                            locale = local;
                        }
                    }
                }
                console.log('locale',locale);
                if(locale){
                    const register = await tempoControl.register(climatempo.token, locale.id);
                    console.log('register',register);

                    const climate = await tempoControl.climate(climatempo.token, location.country);
                    console.log('climate',climate);

                    const climateRain =  await tempoControl.climateRain(climatempo.token, locale.id)
                    console.log('climateRain',climateRain);
                }
            }
            load().then(()=>{
                setLoader({show:false, msg:'', error:false})
            }).catch(err=>{
                setLoader({show:false, msg:'', error:true})
                console.error(err);
            })
        }
    }, [location]);

    return (
        <>
            <Loader type="content" show={loader.show} msg={loader.msg}/>
        </>
    )
})

export default Climate