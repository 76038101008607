import moment from 'moment';
import {createContext, useContext, useEffect, useState} from "react";
import {auth as authControl} from "../control/Auth";
import storage from "../util/storage";
import ConfigContext from "./ConfigContext";
import ControlContext from "./ControlContext";

const AuthContext = createContext({
    user: null,
    signIn: ()=>{},
    signOut: ()=>{}
});

export const AuthContextProvider = ({clientid, children}) => {
    const [user, setUser] = useState(null);
    const [clientID, setClientID] = useState(clientid);
    const [client, setClient] = useState(null);
    const [loading, isLoading] = useState(true);
    const {loading:loadingConfig, rest, error:errorConfig} = useContext(ConfigContext)
    const {setLoadingFull} = useContext(ControlContext);

    const signInStorage = (user)=>{
        storage.set('local','user', user);
    }
    const signOutStorage = ()=>{
        storage.remove('local','user');
    }

    useEffect(()=>{
        if(!loadingConfig){
            if(errorConfig){
                isLoading(false);
            }else{
                console.log('Autenticando Sessão');
                const initAuth = async ()=>{
                    authControl.init(rest, clientID);
                    authControl.on('signIn',(user)=>{
                        if(user && user.user && user.user.cliente !== clientID){
                            signOutStorage();
                            setUser(null)
                            return;
                        }
                        signInStorage(user)
                        setUser(user)
                    })
                    authControl.on('signOut',(rs)=>{
                        signOutStorage();
                        setUser(null)
                    })
                    const localUser = storage.get('local','user');
                    if(localUser){
                        try{
                            const rs = await authControl.validate(localUser.user._id, localUser.token);
                            if(rs.error){
                                signOutStorage();
                                setUser(null)
                            }else if(rs.user){
                                signInStorage(rs)
                                setUser(rs);
                            }else{
                                signOutStorage();
                                setUser(null)
                            }
                        }catch (err){
                            signOutStorage();
                            setUser(null)
                        }
                    }
                    isLoading(false);
                }
                initAuth().then(()=>{}).catch(err=>{
                    console.error(err);
                })
            }
        }
        return ()=> {
            authControl.clear();
        }
    },[loadingConfig])

    useEffect(()=>{
        //console.log('Change User');
        const interval = setInterval(()=>{
            //console.log('setInterval', moment().format('DD/MM/YYYY HH:mm:ss'), user)
            const localUser = storage.get('local','user') || storage.get('local','user');
            if(!localUser && user){
                //console.log('Job unsetUser', localUser, user);
                setLoadingFull(true);
                signOutStorage();
                setUser(null)
            }else if(localUser && !user){
                //console.log('Job setUser', localUser, user);
                signInStorage(localUser);
                setUser(localUser);
            }
        },5000)
        return ()=> {
            //console.log('Clear Interval User');
            clearInterval(interval)
        }
    },[user])


    const signIn = async (opts)=>{
        return await authControl.signIn(opts);
    }
    const signOut = async (opts)=>{
        return await authControl.signOut(opts)
    }
    const validateClient = async (opts)=>{
        return await authControl.validateClient(opts);
    }
    const sleep = (ms)=>{
        return new Promise((resolve, reject) => {
            setTimeout(()=>{
                resolve();
            },ms)
        });
    }
    const context = {
        loading,
        user,
        clientID,
        client,
        setClient,
        signIn,
        signInStorage,
        signOut,
        validateClient,
        sleep
    }

    return (
        <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
    )
}

export default AuthContext