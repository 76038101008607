import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import SafraContext from "../../context/SafraContext";
import Crud from "./cadastro/Crud";
import Common from "../../util/common";
import Loader from "../util/Loader";

const Planejamento = ({view}) => {

    const {t} = useTranslation();

    const {
        listarCadastro,
        cadastroConfiguration,
        getStateCollection,
        listarField,
        salvarWithFieldList,
        salvarFieldItem,
        removerFieldItem,
        updateManyFieldItem,
        importarFieldXLSX,
        clearFieldItemDuplicates,
        limparField,
        getStateMap,
        setCadastroFilter,
        lockCadastro
    } = useContext(SafraContext);

    const toast = useRef(null);
    const refCrudPlanejamento = useRef();
    const refCrudModeloItem = useRef();

    const [loader, setLoader] = useState({show: false, msg: "", error: false})

    const [configuration, setConfiguration] = useState({});
    const [itemConfiguration, setItemConfiguration] = useState({});
    const [data, setData] = useState([]);
    const [dataModeloItens, setDataModeloItens] = useState([]);
    const [selected, setSelected] = useState();
    const [totalHa, setTotalHa] = useState(0);

    useEffect(() => {
        const init = async () => {
            await listarCadastro(view, "unidade");
            await listarCadastro(view, "sub_unidade");
            await listarCadastro(view, "area");
            await listarCadastro(view, "safra");
            await listarCadastro(view, "modelo");
            await listarCadastro(view, "planejamento");
        }
        init().catch(err => {
            console.error(err);
        })
    }, []);

    useEffect(() => {
        if (cadastroConfiguration[view]) {
            setConfiguration(cadastroConfiguration[view]);
            setItemConfiguration(cadastroConfiguration['modelo_item']);
        }
    }, [cadastroConfiguration])

    useEffect(() => {
        const collection = getStateCollection(view);
        //console.log(view, "Atualizando Dados", collection);

        let _totalHa = 0
        for(const [,item] of collection.entries()){
            _totalHa += item.qtdha || 0
        }
        setTotalHa(_totalHa)

        setData(collection);
    }, [getStateCollection(view)])

    const validation = async (item) => {
        return {}
    }

    const onEditIcon = (cell) => {
        const rowData = cell.getRow().getData();
        if (rowData._locked) {
            return;
        }
        openModeloItem(rowData, cell)
    }

    const openModeloItem = (entity, cell) => {
        if (!entity.modelo || !entity.modelo._id) {
            console.error("No Modelo");
            return;
        }
        if (cell) cell.getRow().update({_action_edit: 'loading'});
        setSelected(entity);
        setCadastroFilter({"modelo_item": {modelo: entity.modelo._id}})
        listarField(view, "planejamento", entity._id, "modelo_item").then((rs) => {
            setDataModeloItens((rs) ? rs : [])
            if (cell) cell.getRow().update({_action_edit: ''});
        }).catch(err => {
            console.error(err);
        })
    }

    const onLockEdit = (cell) => {
        const rowData = cell.getRow().getData();
        const _locked = cell.getValue();
        if (!_locked) {
            cell.getRow().deselect();
        }
        cell.getRow().update({_locked: "loading"});
        lockCadastro(view, "planejamento", rowData._id, !_locked).then(() => {
            cell.getRow().update({_locked: !_locked});
            cell.getTable().redraw(true);
        }).catch(err => {
            console.error(err)
        })
    }

    const salvarPlanejamento = async (view, collection, entity) => {
        if (!entity.area) return {};
        for (const [, idArea] of entity.area.entries()) {
            await salvarWithFieldList(view, collection, {...entity, area: idArea}, "modelo", "modelo_item");
        }
        return {}
    }

    const onRemoverPlanejamento = (view, collection, ids) => {
        if (selected && ids && ids.includes(selected._id)) {
            setSelected(null);
        }
    }

    const salvarModeloItem = async (view, collection, entity) => {
        if (!selected) return {};
        const rs = await salvarFieldItem(view, "planejamento", selected._id, "modelo_item", entity);
        const currentPlan = getStateMap("planejamento")[selected._id]
        openModeloItem(currentPlan);
        return rs;
    }

    const removerModeloItem = async (view, collection, ids) => {
        if (!selected) return {};
        const rs = await removerFieldItem(view, "planejamento", selected._id, "modelo_item", ids);
        const currentPlan = getStateMap("planejamento")[selected._id]
        openModeloItem(currentPlan);
        return rs;
    }

    const existsItem = async (view, collection, field, value) => {
        if (!selected) return [];
        return []
    }
    const existsPKItem = async (view, collection, map) => {
        if (!selected) return [];
        return []
    }

    const existsManyItem = async (view, collection, fieldUniqueMap) => {
        if (!selected) return {};
        return {};
    }

    const existsManyPKItem = async (view, collection, pk, fieldPKMap) => {
        if (!selected) return {};
        return {};
    }

    const updateManyItem = async (view, collection, editedCells) => {
        if (!selected) return {};
        const rs = await updateManyFieldItem(view, "planejamento", selected._id, "modelo_item", editedCells)
        const currentPlan = getStateMap("planejamento")[selected._id]
        openModeloItem(currentPlan);
        return rs;
    }

    const importarItemXLSX = async (view, collection, fileData, sheets, headerRowIndex, fieldHeaderIndex, selectedKeyField) => {
        if (!selected) return {};
        const rs = await importarFieldXLSX(view, "planejamento", selected._id, "modelo_item", fileData, sheets, headerRowIndex, fieldHeaderIndex, selectedKeyField)
        const currentPlan = getStateMap("planejamento")[selected._id]
        openModeloItem(currentPlan);
        return rs;
    }

    const clearDuplicates = async (view, collection, selectedKeyField) => {
        if (!selected) return {};
        const rs = await clearFieldItemDuplicates(view, "planejamento", selected._id, "modelo_item", selectedKeyField)
        const currentPlan = getStateMap("planejamento")[selected._id]
        openModeloItem(currentPlan);
        return rs;
    }

    const limparEntityField = async (view, collection) => {
        if (!selected) return {};
        const rs = await limparField(view, "planejamento", selected._id, "modelo_item")
        const currentPlan = getStateMap("planejamento")[selected._id]
        openModeloItem(currentPlan);
        return rs;
    }

    const footer = ()=>{
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body"><b>{t('totalha')}</b>: {totalHa}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>

        <Crud
            ref={refCrudPlanejamento}
            collection={view}
            view={view}
            data={data}
            footer={footer}
            configuration={configuration}
            validation={validation}
            editIcon={onEditIcon}
            noKeyEvents={true}
            lockEdit={onLockEdit}
            persistTable={true}
            action={{
                salvar: salvarPlanejamento
            }}
            listener={{
                remover: onRemoverPlanejamento
            }}
            hide={{
                importarXLSX: true
            }}
        />

        {(selected) ?
            <>
                <div className="row">
                    <div className="col-md-12">
                        <Loader type="content" show={loader.show} msg={loader.msg}/>
                        <div className="card" style={(selected.modelo && selected.modelo.color) ? {backgroundColor: Common.hexToRGBA("#" + selected.modelo.color, 0.1)} : {backgroundColor: '#3682A519'}}>
                            <div className="card-header p-2">
                                <span className="card-title font-weight-bold font-size-lg"></span>
                            </div>
                            <div className="card-body">
                                <Crud
                                    ref={refCrudModeloItem}
                                    key={selected._id}
                                    collection={"modelo_item"}
                                    view={"modelo_item"}
                                    data={dataModeloItens}
                                    title={(selected.modelo) ? selected.modelo.nome : ""}
                                    color={""}
                                    configuration={itemConfiguration}
                                    focus="modelo"
                                    action={{
                                        salvar: salvarModeloItem,
                                        remover: removerModeloItem,
                                        exists: existsItem,
                                        existsPK: existsPKItem,

                                        existsMany: existsManyItem,
                                        existsManyPK: existsManyPKItem,
                                        updateMany: updateManyItem,

                                        importarXLSX: importarItemXLSX,
                                        clearDuplicates: clearDuplicates,
                                        limpar: limparEntityField
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </> : <></>}
        </>
    )

}

export default Planejamento