import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

const Empty = (props) => {

    const {t} = useTranslation();

    useEffect(()=>{

    },[])

    return (
        <></>
    )

}

export default Empty