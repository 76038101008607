import GenericControl from "./GenericControl";

export default class LibControl extends GenericControl {

    /**
     * Generic Database Function
     * @param view {string} view
     * @param context {string} Class
     * @param action {string} Action
     * @param data {{}} Data Object
     * @returns {Promise<{statusText: string, error, status: number}|any>}
     */
    async m(view, context, action, data={}){
        const url = this.api+'/__lib/'+view+'/'+context+'/'+action;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({...data}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': this.user.token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    /**
     * Generci Database Function with fileData
     * @param view {string} View
     * @param context {string} Class
     * @param action {string} Action
     * @param opts {{}} Data Object
     * @param fileData {{}} FileData
     * @param fileName {string} File Name
     * @returns {Promise<void>}
     */
    async mFile(view, context, action, opts={}, fileData={}, fileName= "file.do"){

        const formData = new FormData();
        const blob = new Blob([fileData], { type: 'application/octet-stream' });
        formData.append('file', blob, fileName);
        formData.append('options', JSON.stringify(opts));

        const url = this.api+'/__lib/'+view+'/'+context+'/'+action+'/file';
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
                'x-access-token': this.user.token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }

    }
}

export const lib = new LibControl()