import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "./../../../context/AuthContext";
import SafraContext from "../../../context/SafraContext";
import {Toast} from "primereact/toast";
import {useForm} from "react-hook-form";
import Loader from "../../util/Loader";
import {startLoadingAction, stopLoadingAction} from "../../util/Util";

const User = ({view}) => {

    const {t} = useTranslation();
    const {user, signInStorage} = useContext(AuthContext);
    const {salvarUsuario} = useContext(SafraContext);

    const toast = useRef(null);

    const [loader, setLoader] = useState({show:false, msg:'', error:false})

    const refSubmitBtn = useRef();


    useEffect(()=>{
        if(user){
            setValue("nome", user.user.nome);
            setValue("email", user.user.email);
            setFocus('nome', { shouldSelect: true })
        }
    },[user])

    const {
        register,
        control,
        watch,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm();

    const onSubmit = (data, event)=>{
        //console.log(data);
        const btn = refSubmitBtn.current

        if(!data.nome || data.nome.trim() === ''){
            setError('nome', { type:"manual", message:t('info_campo_vazio',{campo:t('nome')}) })
            return;
        }

        const salvar = async ()=>{
            return await salvarUsuario(view, data);
        }

        startLoadingAction(btn);
        salvar().then((rs)=>{
            stopLoadingAction(btn)
            if(!rs || rs.error){
                toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_salvar'), life: 2000});
                return;
            }

            user.user.nome = data.nome;
            user.user.email = data.email;
            signInStorage(user)

            toast.current.show({severity:'info', summary: t('salvando'), detail:t('sucesso'), life: 2000});
        }).catch(err=>{
            setLoader({show:false, msg:'', error: false})
            console.error(err);
        })
    }

    return (
        <>
            <Loader show={loader.show} msg={loader.msg} type="content" />
            <Toast ref={toast} appendTo={"self"} position="bottom-center" />
            <div className="row">
                <div className="col-lg-6 offset-lg-3">
                    <div className="card">
                        <div className="card-header bg-light p-2"><span className="card-title font-weight-bold font-size-lg">{t("configurar")}</span></div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group mb-1">
                                    <label className="font-weight-bold">{t("nome")}</label>
                                    <input type="text" placeholder="" {...register("nome")} className={"form-control" + ((errors["nome"]) ? " border-danger" : "")}/>
                                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors["nome"]) ? errors["nome"].message : ""}</div>
                                </div>
                                <div className="form-group mb-1">
                                    <label className="font-weight-bold">{t("senha")}</label>
                                    <input type="password" placeholder="" {...register("senha")} className={"form-control" + ((errors["senha"]) ? " border-danger" : "")}/>
                                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors["senha"]) ? errors["senha"].message : ""}</div>
                                </div>
                                <div className="form-group mb-1">
                                    <label className="font-weight-bold">{t("email")}</label>
                                    <input type="text" placeholder="" {...register("email")} className={"form-control" + ((errors["email"]) ? " border-danger" : "")}/>
                                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors["email"]) ? errors["email"].message : ""}</div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button ref={refSubmitBtn} type="submit" className="btn btn-success btn-labeled btn-labeled-left"><b><i className="icon-gear"></i></b> {t("salvar")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default User