// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ptBR_i18n from "./../locales/pt_br.json"
import en_i18n from "./../locales/en.json"

i18n.use(initReactI18next).init({
    resources: {
        pt_br: {
            translation: ptBR_i18n
        },
        en: {
            translation: en_i18n
        }
    },
    lng: "pt_br",
    fallbackLng: "pt_br",
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
