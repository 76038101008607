import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { ColorPicker } from 'primereact/colorpicker';

const RHFColorPicker = forwardRef((props, ref) => {
    const { name, control, defaultValue, handleChange} = props;

    useImperativeHandle(
        ref,
        () => ({

        }),
    );

    return (
        <Controller
            render={({ field }) => (
                <ColorPicker
                    id={field.name}
                    value={field.value || ''}
                    onChange={(e) => field.onChange(e.target.value)}
                    ref={field.ref}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
        />
    );
});

export default RHFColorPicker;
