import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "../../../context/AuthContext";
import SafraContext from "../../../context/SafraContext";
import {Toast} from "primereact/toast";
import Loader from "../../util/Loader";

import tableLang from '../../../locales/tabulator.loc.json'
import {TabulatorFull as Tabulator} from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import {useForm} from "react-hook-form";
import ControlContext from "../../../context/ControlContext";
import BarChart from "../../charts/BarChart";
import RHFSelect from "../../forms/RHFSelect";


const ResumoFazenda = ({view, title}) => {

    const {t} = useTranslation();

    const {user, clientID} = useContext(AuthContext);
    const {titles} = useContext(ControlContext);
    const {resumoFazenda} = useContext(SafraContext);

    const toast = useRef(null);

    const [loader, setLoader] = useState({show:true, msg:"", error:false});
    const [tabulator, setTabulator] = useState(null);
    const [data, setData] = useState();
    const [columns, setColumns] = useState();

    const SUMs = ["qtdha","faturamento","ttha","volume","custo"]
    const FKs = ["safra", "unidade", "sub_unidade", "cultura", "variedade", "area"]
    const [filterSelectList, setFilterSelectList] = useState({});
    const [selectedFilter, setSelectedFilter] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const selectRef = useRef({});

    const [barChartConfig, setBarChartConfig] = useState({})

    const tabulatorRef = useRef();

    useEffect(() => {
        const init = async ()=>{
            const {result, area} = await resumoFazenda(view);
            //console.log('result', result);
            //console.log('area', area);
            const columns = [
                {"title":t('nome'), "field":"nome", "formatter":(cell)=>{
                        const value = cell.getValue();
                        const row = cell.getRow();
                        const rowData = row.getData();

                        if(rowData.unidade){
                            return rowData.unidade+' - '+value
                        }else if(rowData.cultura){
                            return rowData.cultura+' - '+value
                        }else if(rowData.modelo){
                            return value +' - <span class="font-size-xs text-muted">'+rowData.modelo+'</span>'
                        }else{
                            return value
                        }
                    }},
                {"title":t('volume'), "field":"volume", "formatter": "money", "formatterParams": {"decimal": ",","thousand": ".","precision": 2}},
                {"title":t('custo'), "field":"custo", "formatter": "money", "formatterParams": {"decimal": ",","thousand": ".","precision": 2}},
                {"title":t('faturamento'), "field":"faturamento", "formatter": "money", "formatterParams": {"decimal": ",","thousand": ".","precision": 2}},
                {"title":t('qtdha'), "field":"qtdha", "formatter": "money", "formatterParams": {"decimal": ",","thousand": ".","precision": 2}}
            ];
            setColumns(columns);
            setData(result);
        }
        setLoader({show:true, msg:'', error:false})
        init().then(()=>{
            setLoader({show:false, msg:'', error:false})
        }).catch(err=>{
            setLoader({show:false, msg:'', error:true})
            console.error(err);
        })
        return () => {
            setLoader({show:true, msg:'', error:false})
        };
    }, []);

    useEffect(()=>{
        if(tabulator && data && columns){
            //console.log(data);
            tabulator.setColumns(columns);
            const listMap = buildFilterSelect(data);
            setFilterSelectList(listMap);
            tabulator.redraw();

            setLoader({show:false, msg:"", error:false});
        }
    },[tabulator, data, columns])

    const optionsDT = {
        selectableRows:false,
        height:"100%",
        data:[],

        dataTree:true,
        dataTreeCollapseElement:'<span class="mr-1 cursor-pointer"><i class="icon-minus2"></i></span>',
        dataTreeExpandElement:'<span class="mr-1 cursor-pointer"><i class="icon-plus2"></i></span>',
        dataTreeStartExpanded:[true, false],

        columnHeaderVertAlign:"bottom",
        clipboard:true,
        clipboardCopyRowRange:'selected',
        clipboardCopyStyled:false,
        clipboardCopyConfig:{
            columnHeaders:false,
            columnGroups:false,
            rowGroups:false,
            formatCells:true
        },
        placeholder:t('msg_nenhum_registro'),
        columns:[],

        layout: "fitColumns",
        reactiveData:true,
        locale:'pt-br',
        langs:tableLang,
        addRowPos:'top',
        validationMode:"manual",
        rowFormatter: (row) => {
            const data = row.getData();
            if (row.getTreeParent() === false) {
                row.getElement().style.backgroundColor = "#f4f4f4";
                return;
            }
            if(data._nivel === "sub_unidade"){
                row.getElement().style.backgroundColor = "#F4F4F480";
            }
            if(data.color && data.color !== ""){
                row.getElement().style.backgroundColor = "#"+data.color+"1A";
            }
            if(data.modelo){
                row.getElement().style.backgroundColor = "#"+data.color+"1A";
            }
        }
    };

    useEffect(()=>{
        const tabulatorInstance = new Tabulator(tabulatorRef.current, optionsDT);
        tabulatorInstance.on("tableBuilt", ()=>{
            tabulatorInstance.deselectRow();
            setTabulator(tabulatorInstance);
        });
        return () => {
            if (tabulatorInstance) {
                tabulatorInstance.destroy();
            }
            if (tabulator) {
                tabulator.destroy();
            }
        };
    },[])

    const {
        register,
        control,
        watch,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm();



    const buildFilterSelect = (data)=>{
        const _map = {}
        for(const [, fk] of FKs.entries()){
            _map[fk] = {};
        }

        const buildMap = (name, item, mapIds)=>{
            if(!_map[name][item._id]) _map[name][item._id] = {...item, _children:[]}
            for(const [, key] of FKs.entries()){
                if(key === name) continue;
                if(!_map[name][item._id]["_"+key]) _map[name][item._id]["_"+key] = [];
                if(!_map[name][item._id]["_"+key].includes(mapIds[key]._id)){
                    _map[name][item._id]["_"+key].push(mapIds[key]._id)
                }
            }
        }

        const sum = (filter, item)=>{
            for(const [, key] of SUMs.entries()){
                filter[key] += item[key]
            }
        }
        const zero = (filter)=>{
            for(const [, key] of SUMs.entries()){
                filter[key] = 0
            }
        }

        //Customizar por resumo
        for(const [, safra] of data.entries()){
            zero(safra)
            for(const [, sub_unidade] of safra._children.entries()){
                zero(sub_unidade)
                for(const [, variedade] of sub_unidade._children.entries()){
                    zero(variedade)
                    for(const [, area] of variedade._children.entries()){
                        const mapIds = {safra, sub_unidade, unidade:{_id:sub_unidade._id_unidade}, cultura:{_id:variedade.cultura}, variedade, area}
                        buildMap('safra', safra, mapIds)
                        buildMap('unidade', {_id:sub_unidade._id_unidade, nome: sub_unidade.unidade}, mapIds)
                        buildMap('sub_unidade', sub_unidade, mapIds)
                        buildMap('variedade', variedade, mapIds)
                        buildMap('cultura', {_id:variedade._id_cultura, nome: variedade.cultura}, mapIds)
                        buildMap('area', area, mapIds)
                        sum(variedade, area);
                    }
                    sum(sub_unidade, variedade);
                }
                sum(safra, sub_unidade);
            }
        }

        const listMap= {}
        for(const [, fk] of FKs.entries()){
            listMap[fk] = Object.values(_map[fk])
        }

        return listMap;
    }

    const handleChangeFilter = (name, selectedValue)=>{
        setSelectedFilter((prevState)=>{
            if(!selectedValue){
                const newState = {...prevState};
                delete newState[name];
                return newState;
            }
            return {...prevState, [name]:selectedValue}
        })
    }

    useEffect(() => {
        if(data && selectedFilter){
            const dataCopy = JSON.parse(JSON.stringify(data));
            const _filteredData = dataCopy.filter((safra)=>{
                safra._children = safra._children.filter((sub_unidade)=>{
                    sub_unidade._children = sub_unidade._children.filter((variedade)=>{
                        variedade._children = variedade._children.filter((area)=>{
                            return !(
                                (selectedFilter.area && area._id !== selectedFilter.area.value)
                            );
                        })
                        return !(
                            variedade._children.length === 0 ||
                            (selectedFilter.cultura && variedade._id_cultura !== selectedFilter.cultura.value)
                        );
                    })
                    return !(
                        sub_unidade._children.length === 0 ||
                        (selectedFilter.sub_unidade && sub_unidade._id !== selectedFilter.sub_unidade.value) ||
                        (selectedFilter.unidade && sub_unidade._id_unidade !== selectedFilter.unidade.value)
                    );
                })
                return !(
                    safra._children.length === 0 ||
                    (selectedFilter.safra && safra._id !== selectedFilter.safra.value)
                );
            });

            const listMap = buildFilterSelect(_filteredData);


            const _barChartConfig = {
                indexBy:"sub_unidade",
                keys:['Custo','Faturamento'],
                colors:["#ffbbbb", "#1d955e"],
                data:[]
            }

            for(const [, safra] of _filteredData.entries()){
                for(const [, sub_unidade] of safra._children.entries()){
                    _barChartConfig.data.push({
                        sub_unidade:sub_unidade.unidade+" - "+sub_unidade.nome,
                        Custo:sub_unidade.custo,
                        Faturamento:sub_unidade.faturamento
                    })
                }
            }

            setBarChartConfig(_barChartConfig);
            setFilterSelectList(listMap);
            setFilteredData(_filteredData);

            tabulator.setData(_filteredData);
        }
    }, [data, selectedFilter])

    const exportar_xlsx = async (event)=>{
        if(tabulator && filteredData){

            const flatData = []
            const header = [t('safra'), t('unidade'), t('sub_unidade'), t('cultura'), t('area')]
            for(const [, column] of columns.entries()){
                if(column.field === 'nome') continue;
                header.push(column.title);
            }
            for(const [, safra] of filteredData.entries()){
                for(const [, sub_unidade] of safra._children.entries()){
                    for(const [, cultura] of sub_unidade._children.entries()){
                        for(const [, area] of cultura._children.entries()){
                            const line = [safra.nome, sub_unidade.unidade, sub_unidade.nome, cultura.nome, area.nome]
                            for(const [, column] of columns.entries()){
                                if(column.field === 'nome') continue;
                                line.push(area[column.field]);
                            }
                            flatData.push(line);
                        }
                    }
                }
            }
            const worksheet = window.XLSX.utils.aoa_to_sheet([
                header,
                ...flatData
            ])
            const workbook = window.XLSX.utils.book_new();
            window.XLSX.utils.book_append_sheet(workbook, worksheet, titles[view]);
            window.XLSX.writeFile(workbook, titles[view]+".xlsx");
        }
    }

    return (
        <>
            <Loader type="content" show={loader.show} msg={loader.msg}/>
            <Toast ref={toast} appendTo={"self"} position="bottom-center"/>

            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-2">
                        <div className={"card-header border-bottom p-1 pl-2 header-elements-inline"} style={{backgroundColor: "#f0f2f5"}}>
                            <span className="card-title font-weight-bold font-size-lg">{titles[view]}</span>
                            <div className="header-elements">
                                <button type="button" data-pr-tooltip={t('exportar_tipo', {tipo: 'XLSX'})} data-pr-position="top" className="btn btn-icon btn-light text-green"
                                        onClick={(e) => exportar_xlsx(e)}>
                                    <i className="icon-file-excel"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body border-bottom p-1 w-100">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-2 text-right font-weight-bold">{t('safra')}</label>
                                        <div className="col-lg-10">
                                            <RHFSelect
                                                ref={el => selectRef.current["safra"] = el}
                                                name={"safra"}
                                                placeholder={t('selecione')}
                                                control={control}
                                                options={(filterSelectList && filterSelectList["safra"]) ? filterSelectList["safra"].map((item) => {
                                                    return {label: item.nome, value: item._id}
                                                }) : []}
                                                handleChange={handleChangeFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-md-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-2 text-right font-weight-bold">{t('unidade')}</label>
                                        <div className="col-lg-10">
                                            <RHFSelect
                                                ref={el => selectRef.current["unidade"] = el}
                                                name={"unidade"}
                                                placeholder={t('selecione')}
                                                control={control}
                                                options={(filterSelectList && filterSelectList["unidade"]) ? filterSelectList["unidade"].map((item) => {
                                                    return {label: item.nome, value: item._id}
                                                }) : []}
                                                handleChange={handleChangeFilter}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <label className="col-form-label col-lg-2 text-right font-weight-bold">{t('sub_unidade')}</label>
                                        <div className="col-lg-10">
                                            <RHFSelect
                                                ref={el => selectRef.current["sub_unidade"] = el}
                                                name={"sub_unidade"}
                                                placeholder={t('selecione')}
                                                control={control}
                                                options={(filterSelectList && filterSelectList["sub_unidade"]) ? filterSelectList["sub_unidade"].map((item) => {
                                                    return {label: item.nome, value: item._id}
                                                }) : []}
                                                handleChange={handleChangeFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-2 text-right font-weight-bold">{t('cultura')}</label>
                                        <div className="col-lg-10">
                                            <RHFSelect
                                                ref={el => selectRef.current["cultura"] = el}
                                                name={"cultura"}
                                                placeholder={t('selecione')}
                                                control={control}
                                                options={(filterSelectList && filterSelectList["cultura"]) ? filterSelectList["cultura"].map((item) => {
                                                    return {label: item.nome, value: item._id}
                                                }) : []}
                                                handleChange={handleChangeFilter}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <label className="col-form-label col-lg-2 text-right font-weight-bold">{t('area')}</label>
                                        <div className="col-lg-10">
                                            <RHFSelect
                                                ref={el => selectRef.current["area"] = el}
                                                name={"area"}
                                                placeholder={t('selecione')}
                                                control={control}
                                                options={(filterSelectList && filterSelectList["area"]) ? filterSelectList["area"].map((item) => {
                                                    return {label: item.nome, value: item._id}
                                                }) : []}
                                                handleChange={handleChangeFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"card-header border-bottom p-1"} style={{backgroundColor: "#f0f2f5"}}></div>
                        <div style={{position: "relative"}}>
                            <Loader type="card" show={loader.show} msg={loader.msg}/>
                            <div ref={tabulatorRef}></div>
                        </div>
                        <div className={"card-header border-bottom p-1"} style={{backgroundColor: "#f0f2f5"}}></div>
                        <div className="card-body p-2">
                            <div style={{height: "400px"}}>
                                <BarChart config={barChartConfig}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ResumoFazenda