import {useCallback, useContext, useEffect, useRef, useState} from "react";

import Menu from "./Menu";
import Content from "./Content";
import Loader from "./util/Loader";
import {Helmet} from "react-helmet";
import AuthContext from "../context/AuthContext";
import ControlContext from "../context/ControlContext";
import ConfigContext from "../context/ConfigContext";
import {useTranslation} from "react-i18next";


const Main = ({view, view2}) => {
    const {t} = useTranslation();

    const {loading: loadingConfig, error:errorConfig} = useContext(ConfigContext);
    const {loading: loadingAuth, clientID, setClient, client, validateClient} = useContext(AuthContext);
    const {loadingFull, errorMap} = useContext(ControlContext);

    const [error, setError] = useState()

    const contentRef = useRef();
    const menuRef = useRef();

    useEffect(()=>{
        if(!loadingConfig && !loadingAuth){
            if(errorConfig){
                setError(errorConfig);
            }else{
                validateClient().then((rs)=>{
                    if(!rs.error){
                        setClient(rs);
                        //isLoadingFull(false);
                        setError(null);
                    }else{
                        setError(errorMap[rs.error]);
                    }
                }).catch(err=>{
                    console.error(err);
                    setError(t('error_server'));
                })
            }
        }
    },[loadingConfig, loadingAuth])

    return (
        <>
            <Loader type="full" show={loadingFull} msg={error} error={!!error} />
            <Helmet>
                <title>{(client) ? client.nome : 'Default'}</title>
            </Helmet>
            {
                (client) ?
                    <>
                        <Menu ref={menuRef} view={view} view2={view2} />
                        <Content key={view} ref={contentRef} view={view} view2={view2} />
                    </> : <></>
            }
        </>
    )
}
export default Main