import {useTranslation} from "react-i18next";
import React, {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import TableCrud from "../../util/TableCrud";
import ControlContext from "../../../context/ControlContext";
import ModalNew from "../modal/ModalNew";

import {Tooltip} from "primereact/tooltip";
import {Toast} from "primereact/toast";

import SafraContext from "../../../context/SafraContext";
import Swal from "sweetalert2";
import {startLoadingAction, stopLoadingAction} from "../../util/Util";
import ModalImportarXLSX from "../modal/ModalImportarXLSX";
import ModalClearDuplicates from "../modal/ModalClearDuplicates";
import Common from "../../../util/common";

const Crud = forwardRef((props, ref) => {

    const {
        collection,
        view,
        data=[],
        preload= [],
        persistTable = false,
        title,
        color,
        footer,
        validation = async ()=>{},
        configuration = {},
        focus = 'nome',
        noKeyEvents,
        editIcon,
        action={},
        listener={},
        hide={},
        lockEdit } = props;

    useImperativeHandle(
        ref,
        () => ({
            isEditMode(){
                return (tableRef && tableRef.current) ? tableRef.current.isEditMode() : false;
            },
            getSelectedTableData(){
                return (tableRef && tableRef.current) ? tableRef.current.getSelectedData() : [];
            },
            getTabulator(){
                return (tableRef && tableRef.current) ? tableRef.current.tabulator() : null;
            }
        }),
    )

    const {t} = useTranslation();

    const {titles} = useContext(ControlContext);
    const {listarCadastro, updateManyCadastro, removerCadastro, existsManyCadastro, existsManyPKCadastro, cadastroConfigurationFKMap, cadastroFilter, limparCadastro} = useContext(SafraContext);

    const [loader, setLoader] = useState({show:true, msg:"", error:false})

    const [configMap, setConfigMap] = useState({});
    const [tableData, setTableData] = useState([])
    const [tableColumns, setTableColumns] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editedCells, setEditedCells] = useState({})
    const [table, setTable] = useState(null);

    const toast = useRef(null);
    const tableRef = useRef();

    const refTooltip = useRef();
    const refBtnInserir = useRef();
    const refBtnImportar = useRef();
    const refBtnEditar = useRef();
    const refBtnEditarConfirmar = useRef();
    const refBtnEditarCancelar = useRef();
    const refBtnRemover = useRef();
    const refBtnClearDuplicates = useRef();
    const refBtnLimpar = useRef();

    const refBtnExportarXLSX = useRef();

    const refDivInserir = useRef();
    const refDivEditar = useRef();
    const refDivRemover = useRef();


    const handleKey = (event) => {
        //console.log(event);
        if (event.key === '+') {
            newItem();
        }else if (event.keyCode === 46) { //Delete Key
            remover().catch(err=>{
                console.error(err);
            })
        }
    };

    useEffect(() => {
        const init = async ()=>{
            for(const [, c] of preload.entries()){
                //console.log('preload', c);
                await listarCadastro(view, c);
            }
            await listarCadastro(view, collection)
        }
        init().catch(err=>{
            console.error(err);
        })

        if(!noKeyEvents){
            window.addEventListener('keydown', handleKey);
        }
        return () => {
            if(!noKeyEvents){
                window.removeEventListener('keydown', handleKey);
            }
        };
    }, []);

    useEffect(()=>{
        if(cadastroFilter && cadastroFilter[collection]){
            tableRef.current.setLoader({show:true, msg:"", error:false});
            listarCadastro(view, collection).catch((err)=>{
                console.error(err);
            });
        }
    },[cadastroFilter])

    useEffect(() => {
        if(configuration && Object.keys(configuration).length > 0){
            setTable(configuration.table);
        }
    }, [configuration]);

    useEffect(()=>{
        //console.log(editedCells);
    },[editedCells])

    const referenceEditor = (cell, onRendered, success, cancel, editorParams)=>{
        //cell - the cell component for the editable cell
        //onRendered - function to call when the editor has been rendered
        //success - function to call to pass the successfully updated value to Tabulator
        //cancel - function to call to abort the edit and return to a normal cell
        //editorParams - params object passed into the editorParams column definition property
        const row = cell.getRow();
        const rowData = row.getData();
        const columnId = cell.getField();
        const cellValue = cell.getValue();
        const fieldItem = editorParams.fieldItem

        const editor = document.createElement("select");
        editor.style.width = "100%";


        let values = [];
        if(editorParams.refMap[columnId]){
            editorParams.refMap[columnId].map((entry)=>{
                values.push(entry)
            })
        }

        //console.log(editorParams.refFilterItemMap, cellValue);

        const checkDependecies = ()=>{
            const dependsColumnId = editorParams.refFilterItemMap[columnId]
            const dependsCell = cell.getRow().getCell(dependsColumnId);
            const filteredCellValue = dependsCell.getValue();
            if(!filteredCellValue) return [];

            const filteredItem = editorParams.refItemMap[dependsColumnId][filteredCellValue._id]
            if(!filteredItem) return [];

            if(cadastroConfigurationFKMap[columnId] && cadastroConfigurationFKMap[columnId][dependsColumnId]){
                //console.log('1:N', 'Depends',dependsColumnId, 'ColumnID', columnId)
                const filteredValues = values.filter((entry)=>{
                    return (entry[dependsColumnId] && entry[dependsColumnId]._id === filteredItem._id);
                })
                return [...filteredValues];
            }else if(cadastroConfigurationFKMap[dependsColumnId] && cadastroConfigurationFKMap[dependsColumnId][columnId]){
                //console.log('N:1', 'Depends',dependsColumnId, 'ColumnID', columnId)
                if(filteredItem[columnId]) return [filteredItem[columnId]]
                else return [];
            }
        }
        if(editorParams.refFilterItemMap[columnId]){
            values = checkDependecies();
        }

        values.forEach(entry => {
            let optionElement = document.createElement("option");
            optionElement.value = entry._id;
            optionElement.textContent = entry[fieldItem._crud_label];
            editor.appendChild(optionElement);
        });

        if (values.length === 0) {
            let placeholderOption = document.createElement("option");
            placeholderOption.value = "";
            placeholderOption.textContent = t('msg_nenhum_registro');
            placeholderOption.disabled = true;
            placeholderOption.selected = true;
            editor.appendChild(placeholderOption);
        }

        editor.value = (cellValue) ? cellValue._id : "";

        onRendered(()=>{
            editor.focus();
            editor.style.css = "100%";
        });

        editor.addEventListener("change", (e)=>{
            const selectedValue = e.target.value;
            const clearFilters = (field)=>{
                if(field._filter){
                    const relatedCell = cell.getRow().getCell(field._filter);
                    if (relatedCell) {
                        //edited[rowData._id][field._filter] = "";
                        relatedCell.setValue("");
                    }
                    if(editorParams.configMap && editorParams.configMap[field._filter]){
                        clearFilters(editorParams.configMap[field._filter])
                    }
                }
            }
            clearFilters(fieldItem);
            success(editorParams.refItemMap[columnId][selectedValue]);
        });

        return editor;
    };
    const onCellEdited = (cell)=>{
        const columnId = cell.getField();
        const row = cell.getRow();
        const rowData = row.getData();

        const value = cell.getValue();
        const edited = {
            [rowData._id]:{
                [columnId]:(value && typeof value === 'object') ? value._id : value
            }
        }
        setEditedCells((prevState)=>{
            return {...prevState, [rowData._id]:{...prevState[rowData._id], ...edited[rowData._id]}}
        })
    }

    const initSetup = async ()=>{
        if(!table) return;

        const refMap = {}
        const refItemMap = {}
        const refFilterItemMap = {}
        for(const [,item] of table.entries()){
            if(item._type === 'reference'){
                //console.log(view, item.field, "Atualizando Dados");
                refMap[item.field] = await listarCadastro(view, item._crud)
                refItemMap[item.field] = {};
                refMap[item.field].map((entry)=>{
                    refItemMap[item.field][entry._id] = entry;
                })

                if(item._filter){
                    refFilterItemMap[item._filter] = item.field;
                }
            }
        }

        const _configMap = {}
        for(const [,entry] of table.entries()){
            _configMap[entry.field] = entry;
        }
        setConfigMap(_configMap);

        const columns = [];
        if(editIcon){
            columns.push({
                field:"_action_edit",
                editable:false,
                formatter: (cell) => {
                    if(cell.getValue() === 'loading'){
                        return '<i class="icon-spinner4 spinner disabled" style="margin-top: -3px"></i>';
                    }else{
                        const rowData = cell.getRow().getData();
                        return (!rowData._locked) ? '<i class="icon-database-edit2" style="margin-top: -3px"></i>' : ""
                    }
                },
                width: 70,
                hozAlign: "center",
                cellClick: (e, cell) => {
                    //console.log("Cell clicked:", cell.getRow().getData());
                    e.stopPropagation();
                    e.preventDefault();
                    editIcon(cell);
                }
            })
        }

        for(const [,entry] of table.entries()){
            const tableColumn = {}
            for(const attr of Object.keys(entry)){
                if(attr.startsWith('_')) continue;
                tableColumn[attr] = entry[attr];
            }
            if(entry._type === "reference"){
                if(!cadastroFilter[collection] || !cadastroFilter[collection][entry.field]){
                    tableColumn.editor = referenceEditor;
                    tableColumn.editorParams = {
                        refMap,
                        refItemMap,
                        refFilterItemMap,
                        fieldItem:entry,
                        configMap:_configMap
                    }
                }
                tableColumn.formatter = (cell) => {
                    const value = cell.getValue();
                    return (value && typeof value === 'object' && value[entry._crud_label]) ? value[entry._crud_label] : "";
                };
                tableColumn.sorter = (a, b, aRow, bRow, column, dir, sorterParams) => {
                    const nameA = (a && typeof a === 'object' && a[entry._crud_label]) ? a[entry._crud_label].toLowerCase() : "";
                    const nameB = (b && typeof b === 'object' && b[entry._crud_label]) ? b[entry._crud_label].toLowerCase() : "";
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                }
                tableColumn.accessorDownload = (value, data, type, params, column)=>{
                    return (value && typeof value === 'object' && value[entry._crud_label]) ? value[entry._crud_label] : "";
                }
                tableColumn.cssClass = "bg-reference"
            }
            if(entry._metric || (entry.hasOwnProperty("editable") && !entry.editable)){
                tableColumn.cssClass = "bg-metric"
            }
            columns.push(tableColumn);
        }

        if(lockEdit){
            columns.push({
                field:"_locked",
                editable:false,
                formatter: (cell) => {
                    const _locked = cell.getValue();
                    if(_locked === "loading"){
                        return '<i class="icon-spinner4 spinner disabled" style="margin-top: -3px"></i>';
                    }else{
                        if(_locked) {
                            cell.getRow().getElement().style.backgroundColor = Common.hexToRGBA("#cccccc", 0.5);
                        }else{
                            cell.getRow().getElement().style.backgroundColor = "";
                        }
                        return (_locked) ? '<i class="icon-lock2" style="margin-top: -3px"></i>' : '<i class="icon-unlocked2" style="margin-top: -3px"></i>';
                    }
                    //return (_locked) ? '<i class="icon-spinner4 spinner disabled" style="margin-top: -3px"></i>' : '<i class="icon-unlocked2" style="margin-top: -3px"></i>';
                    //icon-spinner4 spinner disabled
                },
                width: 70,
                hozAlign: "center",
                cellClick: (e, cell) => {
                    e.stopPropagation();
                    e.preventDefault();
                    lockEdit(cell);
                }
            })
        }

        setTableColumns(columns);
    }

    useEffect(()=>{
        initSetup().catch(err=>{
            console.error(err);
        })
    },[table])

    useEffect(()=>{
        setTableData(data);
    },[data])

    const exportar_xlsx = ()=>{
        tableRef.current.tabulator().download("xlsx", titles[view]+".xlsx", {});
    }

    const [newModalShow, setNewModalShow] = useState(false);
    const newItem = ()=>{
        setNewModalShow(true);
    }
    const newModalClose = ()=>{
        setNewModalShow(false)
    }

    const remover = async (event)=>{
        const btn = refBtnRemover.current
        startLoadingAction(btn);

        const selectedData = tableRef.current.getSelectedData();

        if(selectedData && selectedData.length > 0){
            const pop = await Swal.fire({
                title: t('info_voce_tem_certeza'),
                text: t('info_registros_removido', {count:selectedData.length}),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('sim'),
                cancelButtonText: t('cancelar')
            })
            if(pop.isConfirmed){
                const ids = []
                for(const [,item] of selectedData.entries()){
                    ids.push(item._id)
                }
                if(listener && listener.remover){
                    listener.remover(view, collection, ids)
                }
                await (action && action.remover) ? action.remover(view, collection, ids) : removerCadastro(view, collection, ids)
                stopLoadingAction(btn);
            }else{
                stopLoadingAction(btn);
            }
        }else{
            stopLoadingAction(btn);
        }
    }

    const [clearModalShow, setClearModalShow] = useState(false);
    const clearDuplicates = ()=>{
        setClearModalShow(true);
    }
    const clearModalClose = ()=>{
        setClearModalShow(false)
    }

    const [dropzoneModalShow, setDropzoneModalShow] = useState(false);
    const importarXLSX = ()=>{
        setDropzoneModalShow(true);
    }
    const dropzoneModalClose = ()=>{
        setDropzoneModalShow(false)
    }

    useEffect(() => {
        if(editMode){
            refBtnEditar.current.classList.remove('btn-light')
            refBtnEditar.current.classList.add('btn-secondary');
            refBtnEditarConfirmar.current.style.display = "inline";
            refBtnEditarCancelar.current.style.display = "inline";

            refDivRemover.current.style.display = "none";
            refDivInserir.current.style.display = "none";
        }else{
            refBtnEditar.current.classList.add('btn-light')
            refBtnEditar.current.classList.remove('btn-secondary');
            refBtnEditarConfirmar.current.style.display = "none";
            refBtnEditarCancelar.current.style.display = "none";

            refDivRemover.current.style.display = "inline";
            refDivInserir.current.style.display = "inline";
        }
    }, [editMode]);

    const modoEditar = ()=>{
        setEditMode(true);
    }

    const modoEditarConfirmar = ()=>{

        if(Object.keys(editedCells).length === 0){
            return;
        }

        //console.log(editedCells);return;

        const btnConfirmar = refBtnEditarConfirmar.current
        startLoadingAction(btnConfirmar);

        const validateAll = async ()=>{
            const generalErrors = {}
            const fieldUniqueMap = {}
            const fieldPKMap = {}
            for(const _id of Object.keys(editedCells)){
                const item = editedCells[_id];
                const row = tableRef.current.tabulator().getRow(_id);

                for(const field of Object.keys(item)){
                    const conf = configMap[field];
                    const value = (item[field]) ? item[field]+"" : "";
                    //console.log(field, value);

                    if(conf.hasOwnProperty('_mandatory') && conf._mandatory && value.trim() === ''){

                        const cell = row.getCell(field);
                        const cellElement = cell.getElement();
                        cellElement.classList.add("tabulator-cell-error");

                        cellElement.setAttribute("data-pr-tooltip", t('info_campo_vazio',{campo:conf.title}));
                        cellElement.setAttribute("data-pr-position", "top");

                        generalErrors[field] = t('info_campo_vazio',{campo:conf.title})
                    }

                    if(conf.hasOwnProperty('_unique') && conf._unique){
                        if(!fieldUniqueMap[_id]) fieldUniqueMap[_id] = {}
                        if(!fieldUniqueMap[_id][field]) fieldUniqueMap[_id][field] = {}
                        fieldUniqueMap[_id][field] = value.trim()
                    }

                    if(configuration && configuration.pk && configuration.pk.includes(field)){
                        if(!fieldPKMap[_id]) fieldPKMap[_id] = {}
                        if(!fieldPKMap[_id][field]) fieldPKMap[_id][field] = {}
                        fieldPKMap[_id][field] = value.trim();
                    }
                }
            }

            const rsExists = (action && action.existsMany) ? await action.existsMany(view, collection, fieldUniqueMap) : await existsManyCadastro(view, collection, fieldUniqueMap);
            for(const _id of Object.keys(rsExists)){
                const row = tableRef.current.tabulator().getRow(_id);
                for(const field of Object.keys(rsExists[_id])){
                    const value = rsExists[_id][field]
                    const cell = row.getCell(field);
                    const cellElement = cell.getElement();
                    cellElement.classList.add("tabulator-cell-error");
                    cellElement.setAttribute("data-pr-tooltip", t('info_valor_existe'));
                    cellElement.setAttribute("data-pr-position", "top");

                    generalErrors[field] = t('info_valor_existe')
                }
            }

            if(configuration && configuration.pk && configuration.pk.length > 0){
                const rsExistsPK = (action && action.existsManyPK) ? await action.existsManyPK(view, collection, configuration.pk, fieldPKMap) :  await existsManyPKCadastro(view, collection, configuration.pk, fieldPKMap);
                for(const _id of Object.keys(rsExistsPK)){
                    const row = tableRef.current.tabulator().getRow(_id);
                    for(const field of Object.keys(rsExistsPK[_id])){
                        const value = rsExistsPK[_id][field]
                        const cell = row.getCell(field);
                        const cellElement = cell.getElement();
                        cellElement.classList.add("tabulator-cell-error");
                        cellElement.setAttribute("data-pr-tooltip", t('info_valor_existe'));
                        cellElement.setAttribute("data-pr-position", "top");

                        generalErrors[field] = t('info_valor_existe')
                    }
                }
            }

            return generalErrors;
        }

        validateAll().then((generalErrors)=>{
            if(Object.keys(generalErrors).length > 0) {
                refTooltip.current.updateTargetEvents();
                toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_update'), life: 2000});
                stopLoadingAction(btnConfirmar);
                return;
            }

            /*console.log(editedCells);
            stopLoadingAction(btnConfirmar);
            return;*/

            for(const _id of Object.keys(editedCells)) {
                const item = editedCells[_id];
                const row = tableRef.current.tabulator().getRow(_id);

            }

            const fnUpdateMany = (action && action.updateMany) ? action.updateMany : updateManyCadastro;
            fnUpdateMany(view, collection, editedCells).then(()=>{
                toast.current.show({severity:'info', summary: t('salvando'), detail:t('sucesso'), life: 2000});
                setEditMode(false);
                stopLoadingAction(btnConfirmar);
            }).catch(err=>{
                toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_salvar'), life: 2000});
                stopLoadingAction(btnConfirmar);
                console.error(err);
            })
        }).catch(err=>{
            toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_salvar'), life: 2000});
            stopLoadingAction(btnConfirmar);
            console.error(err);
        })

    }

    const modoEditarCancelar = ()=>{
        setEditMode(false);
        setEditedCells({});
        listarCadastro(view, collection).catch(err=>{
            console.error(err);
        })
    }

    const limpar = async (event)=>{
        const btn = refBtnLimpar.current
        startLoadingAction(btn);
        const { value: collectionName } = await Swal.fire({
            title: t('msg_limpar_registros'),
            html:t('info_remover_informe_palavra',{value:"<b>"+collection+"</b>"}),
            input: 'text',
            inputValue: '',
            inputPlaceholder: t('info_informe_palavra',{value:""}),
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value || value !== collection) {
                    return t('info_informe_palavra',{value:collection})
                }
            }
        })
        if (collectionName && collectionName === collection) {
            (action && action.limpar) ? await action.limpar(view, collection) :  await limparCadastro(view, collection);
            stopLoadingAction(btn);
        }else{
            stopLoadingAction(btn);
        }
    }

    const rowFormatter = (row)=>{
        //console.log(row.getData());
    }

    const selectableRowsCheck = (row)=>{
        //const rowData = row.getData();
        return true
    }

    const rowClick = (row)=>{
        const rowData = row.getData();
        if(rowData._locked) row.deselect()
    }

    return (
        <>
            <Tooltip ref={refTooltip} target="[data-pr-tooltip]" />
            <Toast ref={toast} appendTo={"self"} position="bottom-center" />
            <div className="row mb-2" style={{height:'40px'}}>
                <div className="col-sm-6 text-left">
                    <div ref={refDivInserir} className="btn-group">
                        <button ref={refBtnInserir} type="button" data-pr-tooltip={t('inserir')} data-pr-position="top" className="btn btn-icon btn-light" onClick={(e) => newItem(e)}>
                            <i className="icon-file-plus"></i>
                        </button>
                        {(!hide || !hide.importarXLSX) ? <>
                            <button ref={refBtnImportar} type="button" data-pr-tooltip={t('importar_tipo', {tipo: 'XLSX'})} data-pr-position="top" className="btn btn-icon btn-light" onClick={(e) => importarXLSX(e)}>
                                <i className="icon-file-download text-success"></i>
                            </button>
                        </> : <></>}
                        <button ref={refBtnExportarXLSX} type="button" data-pr-tooltip={t('exportar_tipo', {tipo: 'XLSX'})} data-pr-position="top" className="btn btn-icon btn-light text-green" onClick={(e) => exportar_xlsx(e)}>
                            <i className="icon-file-excel"></i>
                        </button>
                    </div>
                    <div ref={refDivEditar} className="btn-group ml-2">
                        <button ref={refBtnEditar} type="button" data-pr-tooltip={t('modo_edicao')} data-pr-position="top" className="btn btn-icon btn-light" onClick={(e) => modoEditar(e)}>
                            <i className="icon-compose"></i>
                        </button>
                        <button ref={refBtnEditarConfirmar} type="button" data-pr-tooltip={t('confirmar')} data-pr-position="top" className="btn btn-icon btn-light" style={{display: 'none'}} onClick={(e) => modoEditarConfirmar(e)}>
                            <i className="icon-check text-success"></i>
                        </button>
                        <button ref={refBtnEditarCancelar} type="button" data-pr-tooltip={t('cancelar')} data-pr-position="top" className="btn btn-icon btn-light" style={{display: 'none'}} onClick={(e) => modoEditarCancelar(e)}>
                            <i className="icon-cross"></i>
                        </button>
                    </div>
                </div>
                <div ref={refDivRemover} className="col-sm-6 text-right">
                    <div className="btn-group mr-3">
                        <button ref={refBtnClearDuplicates} type="button" data-pr-tooltip={t('msg_limpar_duplicados')} data-pr-position="left" className="btn btn-icon btn-light text-danger" onClick={(e) => clearDuplicates(e)}>
                            <i className="icon-files-empty"></i>
                        </button>
                        <button ref={refBtnLimpar} type="button" data-pr-tooltip={t('msg_limpar_registros')} data-pr-position="left" className="btn btn-icon btn-danger" onClick={(e) => limpar(e).catch(err => console.error(err))}>
                            <i className="icon-trash-alt"></i>
                        </button>
                    </div>
                    <div className="btn-group">
                        <button ref={refBtnRemover} type="button" data-pr-tooltip={t('msg_remover_selecionados')} data-pr-position="left" className="btn btn-icon btn-light text-danger" onClick={(e) => remover(e).catch(err => {console.error(err)})}>
                            <i className="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>

            <TableCrud
                ref={tableRef}
                name={(title) ? title : titles[view]}
                color={color}
                configuration={configuration}
                data={tableData}
                columns={tableColumns}
                editMode={editMode}
                selectable={true}
                pagination={true}
                onCellEdited={onCellEdited}
                initialSort={[{column: "nome", dir: "asc"}]}
                exportTable={{xlsx: true}}
                rowFormatter={rowFormatter}
                selectableRowsCheck={selectableRowsCheck}
                rowClick={rowClick}
                persist={persistTable}
            />

            {(footer) ? <>{footer()}</> : <></>}

            <ModalNew
                view={view}
                collection={collection}
                title={(title) ? title : titles[view]}
                color={color}
                focus={focus}
                configuration={configuration}
                show={newModalShow}
                close={newModalClose}
                validation={validation}
                action={action}
            />

            <ModalImportarXLSX
                view={view}
                collection={collection}
                configuration={configuration}
                show={dropzoneModalShow}
                close={dropzoneModalClose}
                label={t('importar')}
                description="XLSX"
                accept={{
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
                }}
                action={action}
            />

            <ModalClearDuplicates
                view={view}
                collection={collection}
                configuration={configuration}
                show={clearModalShow}
                close={clearModalClose}
                action={action}
            />
        </>
    )

})

export default Crud