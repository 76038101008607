import React from 'react';
import moment from 'moment';
import { Chart } from "react-google-charts";

const CalendarTimeline = ({data}) => {
    const options = {
        timeline: {
            rowLabelStyle: { fontName: 'Arial', fontSize: 11, color: '#333' },
            barLabelStyle: { fontName: 'Arial', fontSize: 11 },
            showRowLabels: true,
            showBarLabels: true,
            groupByRowLabel: true,
            singleColor:'#006400'
        },
        hAxis: {
            format: 'MM/yyyy',
            title: 'Data',
        },
        backgroundColor: '#efefef',
        avoidOverlappingGridLines: false,
        legend: { position: 'top' },
        allowHtml: true
    };

    return (
        <Chart
            chartType="Timeline"
            data={data}
            options={options}
            width="100%"
            height="100%"
            chartLanguage="pt-BR"
            language="pt-BR"
        />
    );
};

export default CalendarTimeline;
