import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "../../context/AuthContext";
import SafraContext from "../../context/SafraContext";
import {Toast} from "primereact/toast";
import Loader from "../util/Loader";


const Cadastro = ({view}) => {

    const {t} = useTranslation();

    const {user, clientID} = useContext(AuthContext);

    const toast = useRef(null);

    const [loader, setLoader] = useState({show:true, msg:"", error:false});

    useEffect(()=>{
        if(user){}
        setLoader({show:false, msg:'', error:false})
        return () => {
            setLoader({show:true, msg:'', error:false})
        };
    },[user])

    return (
        <>
            <Loader type="content" show={loader.show} msg={loader.msg}/>
            <Toast ref={toast} appendTo={"self"} position="bottom-center" />
        </>
    )

}

export default Cadastro