import moment from "moment";

export default class {

    constructor() {}

    static sortByField(list, field){
        list.sort((o1,o2)=>{
            if(!o1[field] && !o2[field]){
                return 0;
            }else if(!o1[field]){
                return 1;
            }else if(!o2[field]){
                return -1
            }
            if(o1[field] > o2[field]) return 1;
            else if(o1[field] < o2[field]) return  -1;
            else return  0;
        });
        return list;
    }

    static sortByMomentField(list, field, format){
        list.sort((o1,o2)=>{
            if(!o1[field] && !o2[field]){
                return 0;
            }else if(!o1[field]){
                return 1;
            }else if(!o2[field]){
                return -1
            }
            if (moment(o1[field], format).isBefore(moment(o2[field], format))) return -1;
            else if(moment(o1[field], format).isAfter(moment(o2[field], format))) return  1;
            else return  0;
        });
        return list;
    }

    static deepFind(obj, path, def) {
        let paths = path.split('.')
        let current = obj
        for (let i = 0; i < paths.length; i++) {
            if (!current.hasOwnProperty(paths[i]) || !current[paths[i]]) {
                return def;
            } else {
                current = current[paths[i]];
            }
        }
        return current;
    }

    /**
     * Order Map values
     * @param map {Object}
     * @param reverse {boolean}
     * @returns {{}}
     */
    static orderMap(map, reverse){
        const rs = {}
        Object.entries(map).sort((a, b) => {
            return (reverse) ? (b[1] - a[1]) : (a[1] - b[1])
        }).map((item)=>{
            rs[item[0]] = item[1];
        })
        return rs;
    }

    static sortByDate(list, field, format){
        list.sort((o1,o2)=>{
            if(!o1[field] && !o2[field]){
                return 0;
            }else if(!o1[field]){
                return 1;
            }else if(!o2[field]){
                return -1
            }
            if (moment(o1[field], format).isBefore(moment(o2[field], format))) return -1;
            else if(moment(o1[field], format).isAfter(moment(o2[field], format))) return  1;
            else return  0;
        });
        return list;
    }

    /**
     * @param startDate
     * @param endDate
     * @param format
     * @param unitOfTime
     * @returns {*[]}
     */
    static enumerateDates = (startDate, endDate, format, unitOfTime)=>{
        const dates = [];
        const currDate = moment(startDate, format).startOf(unitOfTime).utc(true);
        const lastDate = moment(endDate, format).endOf(unitOfTime).utc(true);
        dates.push(currDate.clone().utc(true).format(format));
        while(currDate.add(1, unitOfTime).diff(lastDate) < 0) {
            dates.push(currDate.clone().utc(true).format(format));
        }
        return dates;
    };

    /**
     * Convert Hex Color to RGBA
     * @param hex
     * @param opacity
     * @returns {string}
     */
    static hexToRGBA(hex, opacity){
        let r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (opacity){
            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        } else {
            return `rgb(${r}, ${g}, ${b})`;
        }
    }

    /**
     * Get filename extension
     * @param filename
     * @returns {string}
     */
    static getExt(filename){
        let ext = null;
        if(filename && filename.lastIndexOf('.') !== -1){
            ext = filename.toLowerCase().substring(filename.lastIndexOf('.')+1, filename.length);
        }
        return ext;
    }
};