import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";

import AuthContext from "../context/AuthContext";
import ControlContext from "../context/ControlContext";

import LeftSidebar from "./util/LeftSidebar";
import RightSidebar from "./util/RightSidebar";

import View from "./View";

const Content = forwardRef((props, ref) => {

    const {view, view2} = props;
    const {user, client} = useContext(AuthContext);
    const {leftSidebar, setLeftSidebar, setRightSidebar, rightSidebar, menu, paths} = useContext(ControlContext);

    const [showRightSideBar, setShowRightSideBar] = useState(false);

    const [selectedView, setSelectedView] = useState(view);

    useImperativeHandle(
        ref,
        () => ({}),
    )

    useEffect(() => {
        if(view2) {
            setSelectedView(view2);
        }else{
            setSelectedView(view);
        }
    }, [view2]);

    useEffect(()=>{
        if(menu && menu[view] && menu[view].menu){
            setLeftSidebar(true);
        }else{
            setLeftSidebar(false);
        }
        return ()=> {setLeftSidebar(false);}
    },[menu])

    useEffect(() => {
        if(showRightSideBar){
            setRightSidebar(true);
        }else{
            setRightSidebar(false);
        }
        return ()=> {setRightSidebar(false);}
    }, [showRightSideBar]);

    return (
        <>
            {(user && client) ? <>
                <div className="d-flex flex-row" style={{
                    backgroundColor: '#1d955e',
                    color: '#fff',
                    textAlign: "left",
                    padding: '5px 5px 5px 20px'
                }}>
                    <div className="w-100 font-weight-bold font-size-lg"></div>
                </div>
                <div className="d-flex flex-row" style={{
                    backgroundColor: '#fff',
                    color: '#000',
                    textAlign: "left",
                    padding: '5px 5px 5px 20px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.125)'
                }}>
                    <div className="w-100 font-weight-bold font-size-lg">{(paths[selectedView]) ? paths[selectedView] : ""}</div>
                </div>
                <div className="page-content p-0" style={{width: "100%", height: "100%"}}>
                    {(leftSidebar && menu && menu[view]) ? <LeftSidebar menu={menu[view].menu} view={view}/> : <></>}
                    <div className="content-wrapper">
                        <div className="content m-0" style={{width: "100%", height: "100%", position: "relative"}}>
                            <View key={selectedView} view={selectedView}/>
                        </div>
                    </div>
                    {(rightSidebar) ? <RightSidebar/> : <></>}
                </div>
            </> : ''}
        </>
    )
});

export default Content