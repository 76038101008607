import GenericControl from "./GenericControl";

export default class UtilControl extends GenericControl {

    /**
     * Get Cadastro Configuration
     * @returns {Promise<{statusText: string, error, status: number}|any>}
     */
    async getCadastroConfiguration(){
        const url = this.api+'/__util/cadastro/json';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-clientid':this.client,
                'x-access-token': this.user.token,
                'x-access-userid': this.user.user._id,
                'x-access-userlogin': this.user.user.login
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }
}

export const util = new UtilControl()