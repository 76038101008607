import {createContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import Painel from "../components/views/Painel";
import Teste from "../components/views/Teste";
import Cadastro from "../components/views/Cadastro";
import Safra from "../components/views/cadastro/Safra";
import Grupo from "../components/views/adm/Grupo";
import Usuario from "../components/views/adm/Usuario";
import Empty from "../components/views/Empty";
import Unidade from "../components/views/cadastro/Unidade";
import SubUnidade from "../components/views/cadastro/SubUnidade";
import Area from "../components/views/cadastro/Area";
import Cultura from "../components/views/cadastro/Cultura";
import Variedade from "../components/views/cadastro/Variedade";
import Fornecedor from "../components/views/cadastro/Fornecedor";

import Produto from "../components/views/cadastro/Produto";
import GrupoProduto from "../components/views/cadastro/GrupoProduto";
import UnidadeMedida from "../components/views/cadastro/UnidadeMedida";
import Maquina from "../components/views/cadastro/Maquina";
import PrevisaoVenda from "../components/views/cadastro/PrevisaoVenda";
import User from "../components/views/adm/User";

import Modelo from "../components/views/Modelo";
import Planejamento from "../components/views/Planejamento";
import Cronograma from "../components/views/relatorio/Cronograma";
import Relatorio from "../components/views/Relatorio";
import ResumoFornecedor from "../components/views/relatorio/ResumoFornecedor";
import ResumoProduto from "../components/views/relatorio/ResumoProduto";
import ResumoCultura from "../components/views/relatorio/ResumoCultura";
import ResumoFazenda from "../components/views/relatorio/ResumoFazenda";

const ControlContext = createContext({});


export const ControlContextProvider = ({clientid, children}) => {

    const {t} = useTranslation();

    const [loadingFull, setLoadingFull] = useState(true);
    const [errorMap, setErrorMap] = useState({});

    const [leftSidebar, setLeftSidebar] = useState(false);
    const [rightSidebar, setRightSidebar] = useState(false);

    const [menu,setMenu] = useState();
    const [menuMap, setMenuMap] = useState();

    const [paths, setPaths] = useState({});
    const [titles, setTitles] = useState({});
    const [views, setViews] = useState({})

    /*
    get ERROR_LOGIN_AUTH(){return 100}
    get ERROR_LOGIN_USER_PWD() {return 101}
    get ERROR_INVALID_TOKEN() {return 102}
    get ERROR_NO_TOKEN() {return 103}
    get ERROR_INVALID_TOKEN_USER() {return 104}
    get ERROR_INVALID_CLIENT(){return 105}
    * */
    useEffect(()=>{
        setErrorMap({
            '100':t('error_login'),
            '101':t('error_invalid_login'),
            '102':t('error_invalid_token'),
            '103':t('error_no_token'),
            '104':t('error_invalid_token_user'),
            '105':t('error_invalid_client'),

            '10':t('error_invalid_client'),
            '11':t('error_license_expired')
        })

        setViews({
            "empty": (props) => <Empty {...props} />,
            "teste": (props) => <Teste {...props} />,

            "painel": (props) => <Painel {...props} />,
            "user": (props) => <User {...props} />,

            "adm_grupo": (props) => <Grupo {...props} />,
            "adm_usuario": (props) => <Usuario {...props} />,

            "cadastro": (props) => <Cadastro {...props} />,

            "safra": (props) => <Safra {...props} />,

            "unidade": (props) => <Unidade {...props} />,
            "sub_unidade": (props) => <SubUnidade {...props} />,
            "area": (props) => <Area {...props} />,

            "cultura": (props) => <Cultura {...props} />,
            "variedade": (props) => <Variedade {...props} />,

            "fornecedor": (props) => <Fornecedor {...props} />,

            "produto":(props) => <Produto {...props} />,
            "grupo_produto":(props) => <GrupoProduto {...props} />,
            "unidade_medida":(props) => <UnidadeMedida {...props} />,

            "previsao_venda":(props) => <PrevisaoVenda {...props} />,
            "maquina":(props) => <Maquina {...props} />,

            "modelo":(props) => <Modelo {...props} />,
            "planejamento":(props) => <Planejamento {...props} />,

            "relatorio":(props) => <Relatorio {...props} />,

            "cronograma":(props) => <Cronograma {...props} />,
            "resumo_fornecedor":(props) => <ResumoFornecedor {...props} />,
            "resumo_produto":(props) => <ResumoProduto {...props} />,
            "resumo_fazenda":(props) => <ResumoFazenda {...props} />,
            "resumo_cultura":(props) => <ResumoCultura {...props} />

        });
    },[])

    useEffect(() => {

        const mapPaths = {
            "user":t('configurar_usuario')
        }
        const mapTitles = {
            "user":t('configurar_usuario')
        }
        const _menuMap = {}
        const loadMenu = (menu, parent)=>{
            if(!menu) return;
            for(const key of Object.keys(menu)){
                const item = menu[key];
                _menuMap[key] = item;

                mapPaths[key] = (parent) ?  parent+' / '+item.title : item.title
                mapTitles[key] = item.title

                if(item.children){
                    loadMenu(item.children, mapPaths[key]);
                }if(item.menu){
                    loadMenu(item.menu, mapPaths[key]);
                }
            }
        }
        loadMenu(menu);

        setMenuMap(_menuMap);
        setPaths(mapPaths);
        setTitles(mapTitles);

    }, [menu]);


    const context = {
        loadingFull,
        setLoadingFull,
        errorMap,
        leftSidebar,
        setLeftSidebar,
        rightSidebar,
        setRightSidebar,
        setMenu,
        menu,
        menuMap,
        titles,
        paths,
        views
    }

    return (
        <ControlContext.Provider value={context}>{children}</ControlContext.Provider>
    )
}

export default ControlContext