import {createContext, useContext, useEffect, useState} from "react";
import AuthContext from "./AuthContext";
import ConfigContext from "./ConfigContext";
import {lib as libControl} from "./../control/Lib"
import {util as utilControl} from "./../control/Util"

const SafraContext = createContext({});

export const SafraContextProvider = ({children}) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const {user, clientID, signOut} = useContext(AuthContext);
    const {rest} = useContext(ConfigContext);

    const [cadastroFilter, setCadastroFilter] = useState({})

    const [cadastroConfiguration, setCadastroConfiguration] = useState({});
    const [cadastroConfigurationTableMap, setCadastroConfigurationTableMap] = useState({});
    const [cadastroConfigurationFKMap, setCadastroConfigurationFKMap] = useState({});

    const [c_safra, setC_safra] = useState([]);
    const [c_unidade, setC_unidade] = useState([]);
    const [c_subUnidade, setC_subUnidade] = useState([]);
    const [c_area, setC_area] = useState([]);
    const [c_cultura, setC_cultura] = useState([]);
    const [c_variedade, setC_variedade] = useState([]);
    const [c_modelo, setC_modelo] = useState([]);
    const [c_modelo_item, setC_modelo_item] = useState([]);
    const [c_fornecedor, setC_fornecedor] = useState([]);
    const [c_produto, setC_produto] = useState([]);
    const [c_grupo_produto, setC_grupo_produto] = useState([]);
    const [c_unidade_medida, setC_unidade_medida] = useState([]);
    const [c_previsao_venda, setC_previsao_venda] = useState([]);
    const [c_maquina, setC_maquina] = useState([]);
    const [c_planejamento, setC_planejamento] = useState([]);

    const [c_stateMap, setC_stateMap] = useState({})

    const stateMap = {
        safra: [c_safra, setC_safra],
        unidade: [c_unidade, setC_unidade],
        sub_unidade: [c_subUnidade, setC_subUnidade],
        area: [c_area, setC_area],
        cultura:  [c_cultura, setC_cultura],
        variedade: [c_variedade, setC_variedade],
        modelo:[c_modelo, setC_modelo],
        modelo_item:[c_modelo_item, setC_modelo_item],
        fornecedor: [c_fornecedor, setC_fornecedor],
        produto:[c_produto, setC_produto],
        grupo_produto:[c_grupo_produto, setC_grupo_produto],
        unidade_medida:[c_unidade_medida, setC_unidade_medida],
        previsao_venda:[c_previsao_venda, setC_previsao_venda],
        maquina:[c_maquina, setC_maquina],
        planejamento:[c_planejamento, setC_planejamento]
    };

    const setCadastroState = (key, value)=>{
        if (stateMap[key]) {
            const [, setter] = stateMap[key];
            setter(value);
        } else {
            console.error(`State "${key}" não encontrado.`);
        }
    }

    /**
     * Get Collection state
     * @param collection {string}
     * @returns {*}
     */
    const getStateCollection = (collection)=>{
        //console.log(collection, stateMap[collection][0]);
        return stateMap[collection][0]
    }

    const getStateMap = (collection)=>{
        return c_stateMap[collection];
    }

    const initialLoadingCadastro = async ()=>{
        await listarCadastro("modelo","modelo");
        await listarCadastro("planejamento","planejamento");
    }

    useEffect(()=>{
        if(user){
            libControl.init(rest, clientID, user);
            utilControl.init(rest, clientID, user);
            utilControl.getCadastroConfiguration().then((rs)=>{
                if(rs.error){
                    console.error(rs.error);
                    signOut(user);
                    return;
                }
                setCadastroConfiguration(rs);

                const _configurationTableMap = {}
                for(const key of Object.keys(rs)){
                    const _configTableMap = {}
                    const _config = rs[key];
                    if(_config.table){
                        _config.table.map((item)=>{
                            _configTableMap[item.field] = item;
                        })
                    }
                    _configurationTableMap[key] = _configTableMap
                }
                setCadastroConfigurationTableMap(_configurationTableMap);

                const _fkMap = {}
                for(const key of Object.keys(rs)){
                    const config = rs[key];
                    if(config.table){
                        for(const [,item] of config.table.entries()){
                            if(item._type === "reference"){
                                if(!_fkMap[key]) _fkMap[key] = {}
                                _fkMap[key][item._crud] = _configurationTableMap[key]
                            }
                        }
                    }
                }
                setCadastroConfigurationFKMap(_fkMap)

                initialLoadingCadastro().then(()=>{
                    setLoading(false);
                }).catch(err=>{
                    console.error(err);
                    setLoading(false);
                    signOut(user);
                })
            }).catch(err=>{
                console.error(err);
                setLoading(false);
                signOut(user);
            })
        }
        return ()=> {}
    },[user])

    const api = async (view, context, action, data)=>{
        return await libControl.m(view, context, action, data)
    }

    /**
     *
     * @param view {string}
     * @param data {{}}
     * @param data.nome {string}
     * @param data.senha {string}
     * @param data.email {string}
     * @returns {Promise<{statusText: string, error, status: number}|*>}
     */
    const salvarUsuario = async (view, data)=>{
        return await libControl.m(view, "usuario", "updateUserInfo", data)
    }

    /**
     * Listar Cadastro
     * @param view {string}
     * @param collection {string}
     * @returns {Promise<void>}
     */
    const listarCadastro = async (view, collection)=>{
        if(!collection){
            throw 'No Collection'
        }
        //console.log('Loading', collection);
        const filter = (cadastroFilter[collection]) ? cadastroFilter[collection] : {}
        const rs = await libControl.m(view, 'cadastro', 'listar', {collection, filter});
        if(!rs.error){
            setCadastroState(collection, rs.list);
            setC_stateMap((current)=>{
                return {...current, [collection]: rs.map}
            })
            return rs.list;
        }else{
            throw rs;
        }
    }

    /**
     * Update Cadastro
     * @param view {string}
     * @param collection {string}
     * @param id {{string}}
     * @param set {{}} Update Data
     * @returns {Promise<void>}
     */
    const updateCadastro = async (view, collection, id, set)=>{
        if(!collection){
            throw 'No Collection'
        }
        const rs = await libControl.m(view, 'cadastro', 'update', {collection, set, id});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * Update Cadastro
     * @param view {string}
     * @param collection {string}
     * @param map {{}} Update Data
     * @returns {Promise<void>}
     */
    const updateManyCadastro = async (view, collection, map={})=>{
        if(!collection){
            throw 'No Collection'
        }
        if(!map){
            throw 'No Update Map'
        }
        const rs = await libControl.m(view, 'cadastro', 'updateMany', {collection, map});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * Remover Cadastro
     * @param view {string}
     * @param collection {string}
     * @param ids {[]} IDs
     * @returns {Promise<{statusText: string, error, status: number}|*>}
     */
    const removerCadastro = async (view, collection, ids)=>{
        if(!collection){
            throw 'No Collection'
        }
        const rs = await libControl.m(view, 'cadastro', 'remover', {collection, ids});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    const limparCadastro = async (view, collection) =>{
        if(!collection){
            throw 'No Collection'
        }
        const rs = await libControl.m(view, 'cadastro', 'limpar', {collection});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * Salvar Cadastro
     * @param view {string}
     * @param collection {string}
     * @param entity {{}}
     * @returns {Promise<void>}
     */
    const salvarCadastro = async (view,collection, entity={})=>{
        if(!collection){
            throw 'No Collection'
        }
        const rs = await libControl.m(view,'cadastro', 'salvar', {collection, entity});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * Importar XLSX
     * @param view {string}
     * @param collection {string}
     * @param fileData {{}} FileData
     * @param sheetName {string}
     * @param headerRowIndex {number} Header Index
     * @param campos {{}} Campos
     * @param update {[]} update key fields
     * @returns {Promise<void>}
     */
    const importarCadastroXLSX = async (view, collection, fileData, sheetName="", headerRowIndex, campos={}, update=[])=>{
        if(!collection){
            throw 'No Collection'
        }

        const rs = await libControl.mFile(view,'cadastro','importarXLSX', {
            collection,
            sheetName,
            headerRowIndex,
            campos,
            update
        }, fileData, 'file.xlsx')

        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * @param view {string}
     * @param collection {string}
     * @param field {string}
     * @param value {string}
     * @returns {Promise<{statusText: string, error, status: number}|*>}
     */
    const existsCadastro = async (view, collection, field="", value="")=>{
        if(!collection){
            throw 'No Collection'
        }
        const rs = await libControl.m(view,'cadastro', 'exists', {collection, field, value});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * @param view {string}
     * @param collection {string}
     * @param map {{}} Values [field:value]
     * @returns {Promise<{statusText: string, error, status: number}|*>}
     */
    const existsPKCadastro = async (view, collection, map)=>{
        if(!collection){
            throw 'No Collection'
        }
        const rs = await libControl.m(view,'cadastro', 'existsPK', {collection, map});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * @param view {string}
     * @param collection {string}
     * @param fieldUniqueMap {{}} {_id:values}     *
     * @returns {Promise<{statusText: string, error, status: number}|*>}
     */
    const existsManyCadastro = async (view, collection, fieldUniqueMap)=>{
        if(!collection){
            throw 'No Collection'
        }
        const rs = await libControl.m(view,'cadastro', 'existsMany', {collection, map:fieldUniqueMap});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * Verificar se já existe registro com mesma pk
     * @param view {string}
     * @param collection {string}
     * @param pk {[]} PK
     * @param fieldPKMap {{}} {_id:values}
     * @returns {Promise<{statusText: string, error, status: number}|*>}
     */
    const existsManyPKCadastro = async (view, collection, pk, fieldPKMap)=>{
        if(!collection){
            throw 'No Collection'
        }
        const rs = await libControl.m(view,'cadastro', 'existsManyPK', {collection, pk, map:fieldPKMap});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * Travar item para não editar
     * @param view {string}
     * @param collection {string}
     * @param id {string} _id
     * @param value {boolean}
     * @returns {Promise<{statusText: string, error, status: number}|*>}
     */
    const lockCadastro = async (view, collection, id, value)=>{
        if(!id || !collection){
            throw 'Incorrect Params'
        }
        const rs = await libControl.m(view,'cadastro', 'lock', {collection, id, value});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * @param view {string}
     * @returns {Promise<{statusText: string, error, status: number}|*>}
     */
    const countCollection = async (view)=>{
        const rs = await libControl.m(view,'cadastro', 'countCollections', {collections:Object.keys(stateMap)});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    const clearDuplicates = async (view, collection, pk)=>{
        if(!collection){
            throw 'No Collection'
        }
        const rs = await libControl.m(view,'cadastro', 'clearDuplicates', {collection, pk});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    const duplicarCadastro = async (view, collection, id, references)=>{
        if(!collection){
            throw 'No Collection'
        }
        const rs = await libControl.m(view,'cadastro', 'duplicar', {collection, id, references});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    const listarField = async (view, collection, id, field)=>{
        if(!id || !collection || !id || !field){
            throw 'Incorrect Params'
        }
        const rs = await libControl.m(view,'cadastro', 'listarField', {id, field, collection});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    const salvarWithFieldList = async (view, collection, entity, fk, field)=>{
        if(!entity || !collection || !fk || !field){
            throw 'Incorrect Params'
        }
        const rs = await libControl.m(view,'cadastro', 'salvarWithFieldList', {entity, fk, field, collection});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    const salvarFieldItem = async (view, collection, id, field, entity) => {
        if(!entity || !collection || !id || !field){
            throw 'Incorrect Params'
        }
        const rs = await libControl.m(view,'cadastro', 'salvarFieldItem', {collection, id, field, entity});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    const removerFieldItem = async (view, collection, id, field, ids) => {
        if(!collection || !id || !ids || !field){
            throw 'Incorrect Params'
        }
        const rs = await libControl.m(view,'cadastro', 'removerFieldItem', {collection, id, field, ids});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    const updateManyFieldItem = async (view, collection, id, field, map={})=>{
        if(!collection || !id || !map || !field){
            throw 'Incorrect Params'
        }
        const rs = await libControl.m(view, 'cadastro', 'updateManyFieldItem', {collection, id, field, map});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * Importar Field XLSX
     * @param view {string}
     * @param collection {string}
     * @param id {string}
     * @param field {string}
     * @param fileData {{}} FileData
     * @param sheetName {string}
     * @param headerRowIndex {number} Header Index
     * @param campos {{}} Campos
     * @param update {[]} update key fields
     * @returns {Promise<void>}
     */
    const importarFieldXLSX = async (view, collection, id, field, fileData, sheetName="", headerRowIndex, campos={}, update=[])=>{
        if(!collection || !id || !field){
            throw 'Incorrect Params'
        }
        const rs = await libControl.mFile(view,'cadastro','importarFieldXLSX', {
            collection,
            id,
            field,
            sheetName,
            headerRowIndex,
            campos,
            update
        }, fileData, 'file.xlsx')

        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    const clearFieldItemDuplicates = async (view, collection, id, field, pk)=>{
        if(!collection || !id || !field){
            throw 'Incorrect Params'
        }
        const rs = await libControl.m(view,'cadastro', 'clearFieldItemDuplicates', {collection, pk, id, field});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    const limparField = async (view, collection, id, field)=>{
        if(!collection || !id || !field){
            throw 'Incorrect Params'
        }
        const rs = await libControl.m(view,'cadastro', 'limparField', {collection, id, field});
        if(!rs.error){
            await listarCadastro(view, collection);
            return rs;
        }else{
            throw rs;
        }
    }

    const resumoFornecedor = async (view)=>{
        const rs = await libControl.m(view,'relatorio', 'resumoFornecedor', {});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    const resumoProduto = async (view)=>{
        const rs = await libControl.m(view,'relatorio', 'resumoProduto', {});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    const resumoCultura = async (view)=>{
        const rs = await libControl.m(view,'relatorio', 'resumoCultura', {});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    const resumoFazenda = async (view)=>{
        const rs = await libControl.m(view,'relatorio', 'resumoFazenda', {});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    const context = {
        loading,

        cadastroConfiguration,
        cadastroConfigurationTableMap,
        cadastroConfigurationFKMap,

        api,
        getStateCollection,
        getStateMap,
        cadastroViews: Object.keys(stateMap),

        salvarUsuario,

        setCadastroFilter,
        cadastroFilter,

        listarCadastro,
        salvarCadastro,
        removerCadastro,
        limparCadastro,
        updateCadastro,
        updateManyCadastro,
        importarCadastroXLSX,
        existsCadastro,
        existsManyCadastro,
        existsPKCadastro,
        existsManyPKCadastro,
        lockCadastro,
        countCollection,
        clearDuplicates,
        duplicarCadastro,

        listarField,
        salvarWithFieldList,
        salvarFieldItem,
        removerFieldItem,
        updateManyFieldItem,
        importarFieldXLSX,
        clearFieldItemDuplicates,
        limparField,

        resumoFornecedor,
        resumoProduto,
        resumoCultura,
        resumoFazenda
    }

    return (
        <SafraContext.Provider value={context}>{children}</SafraContext.Provider>
    )
}

export default SafraContext