import {createContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
const ConfigContext = createContext({});

export const ConfigContextProvider = ({children}) => {

    const {t} = useTranslation();

    const [config, setConfig] = useState({});
    const [loading, isLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadLocalJSON = async ()=>{
        const response = await fetch(`${process.env.PUBLIC_URL}/config/safra-web.json`, {
            cache: 'no-cache'
        });
        return await response.json()
    }

    const init = async ()=>{
        return await loadLocalJSON();
    }

    useEffect(()=>{
        init().then((rs)=>{
            setConfig(rs);
            isLoading(false);
        }).catch(err=>{
            setError(t('error_server'));
            isLoading(false);
            console.error(err)
        })
        return () => {
            isLoading(true);
            setConfig({});
            setError(null);
        };
    },[])

    const context = {
        loading,
        error,
        title: config.title,
        rest: config.rest,
        version: config.version,
        climatempo: config.climatempo
    }

    return (
        <ConfigContext.Provider value={context}>{children}</ConfigContext.Provider>
    )
}

export default ConfigContext